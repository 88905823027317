<template>
  <div class="d-flex flex-column h-100">
    <div class="cs-box-item" :set="url_list = getUrlList(resultFiles[index])">
      <div class="d-flex align-items-center justify-content-center mb-50">
        <feather-icon
          v-if="
          resultFiles[index] &&
          (resultFiles[index].length) &&
          (typeof resultFiles[index] === 'object')"
          icon="CheckCircleIcon"
          size="22"
          class="text-success mr-auto"
        />
        <feather-icon v-else icon="AlertCircleIcon" size="22" class="text-warning mr-auto" />

        <span
          class="text-primary text-center"
          style="font-weight: 500;font-size:18px;flex:1"
        >{{ title }}</span>
      </div>

      <div class="d-flex flex-column flex-md-row items-center justify-center">
        <div
          v-if="
          resultFiles[index] &&
          (resultFiles[index].length) &&
          (typeof resultFiles[index] === 'object')"
          style="flex:1"
        >
          <template>
            <b-link
              v-for="urlFileUploaded in url_list"
              :key="typeof urlFileUploaded === 'object' ? urlFileUploaded.url : urlFileUploaded"
              :href="typeof urlFileUploaded === 'object' ? urlFileUploaded.url : urlFileUploaded"
              target="_blank"
              class="flex"
              style="gap:6px"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 10H10C10.2652 10 10.5196 9.89464 10.7071 9.70711C10.8946 9.51957 11 9.26522 11 9C11 8.73478 10.8946 8.48043 10.7071 8.29289C10.5196 8.10536 10.2652 8 10 8H9C8.73478 8 8.48043 8.10536 8.29289 8.29289C8.10536 8.48043 8 8.73478 8 9C8 9.26522 8.10536 9.51957 8.29289 9.70711C8.48043 9.89464 8.73478 10 9 10ZM9 12C8.73478 12 8.48043 12.1054 8.29289 12.2929C8.10536 12.4804 8 12.7348 8 13C8 13.2652 8.10536 13.5196 8.29289 13.7071C8.48043 13.8946 8.73478 14 9 14H15C15.2652 14 15.5196 13.8946 15.7071 13.7071C15.8946 13.5196 16 13.2652 16 13C16 12.7348 15.8946 12.4804 15.7071 12.2929C15.5196 12.1054 15.2652 12 15 12H9ZM20 8.94C19.9896 8.84813 19.9695 8.75763 19.94 8.67V8.58C19.8919 8.47718 19.8278 8.38267 19.75 8.3L13.75 2.3C13.6673 2.22222 13.5728 2.15808 13.47 2.11C13.4402 2.10576 13.4099 2.10576 13.38 2.11C13.2784 2.05174 13.1662 2.01434 13.05 2H7C6.20435 2 5.44129 2.31607 4.87868 2.87868C4.31607 3.44129 4 4.20435 4 5V19C4 19.7956 4.31607 20.5587 4.87868 21.1213C5.44129 21.6839 6.20435 22 7 22H17C17.7956 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7956 20 19V9C20 9 20 9 20 8.94ZM14 5.41L16.59 8H15C14.7348 8 14.4804 7.89464 14.2929 7.70711C14.1054 7.51957 14 7.26522 14 7V5.41ZM18 19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H7C6.73478 20 6.48043 19.8946 6.29289 19.7071C6.10536 19.5196 6 19.2652 6 19V5C6 4.73478 6.10536 4.48043 6.29289 4.29289C6.48043 4.10536 6.73478 4 7 4H12V7C12 7.79565 12.3161 8.55871 12.8787 9.12132C13.4413 9.68393 14.2044 10 15 10H18V19ZM15 16H9C8.73478 16 8.48043 16.1054 8.29289 16.2929C8.10536 16.4804 8 16.7348 8 17C8 17.2652 8.10536 17.5196 8.29289 17.7071C8.48043 17.8946 8.73478 18 9 18H15C15.2652 18 15.5196 17.8946 15.7071 17.7071C15.8946 17.5196 16 17.2652 16 17C16 16.7348 15.8946 16.4804 15.7071 16.2929C15.5196 16.1054 15.2652 16 15 16Z"
                  fill="#20419b"
                />
              </svg>
              <span style="flex:1">{{ getFileName(urlFileUploaded) }}</span>
            </b-link>
          </template>
        </div>
        <div
          v-b-tooltip.top
          :title="$t(checkDisabled(product).isDisabled ? isCancelOrder ? 'lbl_the_order_has_been_canceled__and_you_cannot_upload_a_file_' : checkDisabled(product).isSended?'lbl_the_order_has_been_completed__and_you_cannot_upload_a_file_': 'lbl_the_order_is_currently_being_processed__and_you_cannot_upload_a_file_' : '')"
          class="d-flex align-items-center justify-content-center my-1"
        >
          <input
            v-show="false"
            type="file"
            :ref="refId"
            multiple
            @change="(e)=>uploadFile(e.target.files,index)"
          />
          <b-dropdown
            v-if="!disableCreateBtn"
            :disabled="checkDisabled(product).isDisabled"
            class="cs-dropdown"
            id="dropdown-dropright"
            dropright
          >
            <template slot="button-content">
              <svg
                v-if="
                  resultFiles[index] &&
                  (resultFiles[index].length) &&
                  (typeof resultFiles[index] === 'object')
                "
                width="36"
                height="36"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="text-primary"
              >
                <path
                  d="M21 11.9999C20.7348 11.9999 20.4804 12.1053 20.2929 12.2928C20.1054 12.4804 20 12.7347 20 12.9999V18.9999C20 19.2652 19.8946 19.5195 19.7071 19.707C19.5196 19.8946 19.2652 19.9999 19 19.9999H5C4.73478 19.9999 4.48043 19.8946 4.29289 19.707C4.10536 19.5195 4 19.2652 4 18.9999V4.99994C4 4.73472 4.10536 4.48037 4.29289 4.29283C4.48043 4.1053 4.73478 3.99994 5 3.99994H11C11.2652 3.99994 11.5196 3.89458 11.7071 3.70705C11.8946 3.51951 12 3.26516 12 2.99994C12 2.73472 11.8946 2.48037 11.7071 2.29283C11.5196 2.1053 11.2652 1.99994 11 1.99994H5C4.20435 1.99994 3.44129 2.31601 2.87868 2.87862C2.31607 3.44123 2 4.20429 2 4.99994V18.9999C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 21.9999 5 21.9999H19C19.7956 21.9999 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 18.9999V12.9999C22 12.7347 21.8946 12.4804 21.7071 12.2928C21.5196 12.1053 21.2652 11.9999 21 11.9999ZM6 12.7599V16.9999C6 17.2652 6.10536 17.5195 6.29289 17.707C6.48043 17.8946 6.73478 17.9999 7 17.9999H11.24C11.3716 18.0007 11.5021 17.9755 11.6239 17.9257C11.7457 17.8759 11.8566 17.8026 11.95 17.7099L18.87 10.7799L21.71 7.99994C21.8037 7.90698 21.8781 7.79637 21.9289 7.67452C21.9797 7.55266 22.0058 7.42195 22.0058 7.28994C22.0058 7.15793 21.9797 7.02722 21.9289 6.90536C21.8781 6.7835 21.8037 6.6729 21.71 6.57994L17.47 2.28994C17.377 2.19621 17.2664 2.12182 17.1446 2.07105C17.0227 2.02028 16.892 1.99414 16.76 1.99414C16.628 1.99414 16.4973 2.02028 16.3754 2.07105C16.2536 2.12182 16.143 2.19621 16.05 2.28994L13.23 5.11994L6.29 12.0499C6.19732 12.1434 6.12399 12.2542 6.07423 12.376C6.02446 12.4979 5.99924 12.6283 6 12.7599ZM16.76 4.40994L19.59 7.23994L18.17 8.65994L15.34 5.82994L16.76 4.40994ZM8 13.1699L13.93 7.23994L16.76 10.0699L10.83 15.9999H8V13.1699Z"
                  fill="currentColor"
                />
              </svg>

              <svg
                v-else
                width="42"
                height="42"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="text-primary"
              >
                <path d="M13 11H17V13H13V17H11V13H7V11H11V7H13V11Z" fill="currentColor" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4Z"
                  fill="currentColor"
                />
              </svg>
            </template>
            <b-dropdown-item
              @click="form_category === 'orderFormUrls' ? toggleFormSelectModal() : handleCreateRequisitionForm(product)"
              :disabled="checkDisabled(product).isDisabled"
            >
              <button style="color:inherit">
                <span>{{ $t(form_category === 'orderFormUrls' ? 'lbl_create' : product.formData ? 'lbl_change' : 'lbl_create') }}</span>
              </button>
            </b-dropdown-item>
            <b-dropdown-item @click="handleTriggerUploadFile()">
              <span>{{ $t('lbl_upload') }}</span>
            </b-dropdown-item>
          </b-dropdown>
          <button
            v-else
            :disabled="checkDisabled(product).isDisabled"
            v-b-tooltip.top
            :title="$t(checkDisabled(product).isDisabled ? isCancelOrder ? 'lbl_the_order_has_been_canceled__and_you_cannot_upload_a_file_' : checkDisabled(product).isSended?'lbl_the_order_has_been_completed__and_you_cannot_upload_a_file_': 'lbl_the_order_is_currently_being_processed__and_you_cannot_upload_a_file_' : '')"
            class="btn cursor-pointer p-0"
            @click="handleTriggerUploadFile()"
          >
            <svg
              v-if="
                resultFiles[index] &&
                (resultFiles[index].length) &&
                (typeof resultFiles[index] === 'object')"
              width="36"
              height="36"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="text-primary"
            >
              <path
                d="M21 11.9999C20.7348 11.9999 20.4804 12.1053 20.2929 12.2928C20.1054 12.4804 20 12.7347 20 12.9999V18.9999C20 19.2652 19.8946 19.5195 19.7071 19.707C19.5196 19.8946 19.2652 19.9999 19 19.9999H5C4.73478 19.9999 4.48043 19.8946 4.29289 19.707C4.10536 19.5195 4 19.2652 4 18.9999V4.99994C4 4.73472 4.10536 4.48037 4.29289 4.29283C4.48043 4.1053 4.73478 3.99994 5 3.99994H11C11.2652 3.99994 11.5196 3.89458 11.7071 3.70705C11.8946 3.51951 12 3.26516 12 2.99994C12 2.73472 11.8946 2.48037 11.7071 2.29283C11.5196 2.1053 11.2652 1.99994 11 1.99994H5C4.20435 1.99994 3.44129 2.31601 2.87868 2.87862C2.31607 3.44123 2 4.20429 2 4.99994V18.9999C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 21.9999 5 21.9999H19C19.7956 21.9999 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 18.9999V12.9999C22 12.7347 21.8946 12.4804 21.7071 12.2928C21.5196 12.1053 21.2652 11.9999 21 11.9999ZM6 12.7599V16.9999C6 17.2652 6.10536 17.5195 6.29289 17.707C6.48043 17.8946 6.73478 17.9999 7 17.9999H11.24C11.3716 18.0007 11.5021 17.9755 11.6239 17.9257C11.7457 17.8759 11.8566 17.8026 11.95 17.7099L18.87 10.7799L21.71 7.99994C21.8037 7.90698 21.8781 7.79637 21.9289 7.67452C21.9797 7.55266 22.0058 7.42195 22.0058 7.28994C22.0058 7.15793 21.9797 7.02722 21.9289 6.90536C21.8781 6.7835 21.8037 6.6729 21.71 6.57994L17.47 2.28994C17.377 2.19621 17.2664 2.12182 17.1446 2.07105C17.0227 2.02028 16.892 1.99414 16.76 1.99414C16.628 1.99414 16.4973 2.02028 16.3754 2.07105C16.2536 2.12182 16.143 2.19621 16.05 2.28994L13.23 5.11994L6.29 12.0499C6.19732 12.1434 6.12399 12.2542 6.07423 12.376C6.02446 12.4979 5.99924 12.6283 6 12.7599ZM16.76 4.40994L19.59 7.23994L18.17 8.65994L15.34 5.82994L16.76 4.40994ZM8 13.1699L13.93 7.23994L16.76 10.0699L10.83 15.9999H8V13.1699Z"
                fill="currentColor"
              />
            </svg>

            <svg
              v-else
              width="42"
              height="42"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="text-primary"
            >
              <path d="M13 11H17V13H13V17H11V13H7V11H11V7H13V11Z" fill="currentColor" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <b-modal
      size="lg"
      centered
      hide-header
      :ok-title="$t('lbl_create')"
      :cancel-title="$t('lbl_cancel')"
      v-model="showSelectOrderFormModal"
      @ok="handleTriggerSelectOrderForm"
      @hide="toggleFormSelectModal"
    >
      <SelectOrderForm
        :list_form="LIST_FORM"
        :selectingOrderFormCount="selectingOrderFormCount"
        @onCreate="handleSelectOrderForm"
        :disabled="checkDisabled(product).isDisabled"
      />
    </b-modal>

    <b-modal
      size="xl"
      scrollable
      hide-header
      :ok-title="$t('submit_form.lbl_save')"
      :cancel-title="$t('lbl_cancel')"
      v-model="showModalRequisitionForm"
      @ok="handleTriggerRequisitionForm"
      @hide="closeModalRequisitionForm"
    >
      <component
        :is="currentForm.component"
        :formName="currentFormName"
        @onCreate="handleSubmitRequisitionForm"
        @onChangeFormData="handleRefreshData"
        :createRequisitionFormCount="createRequisitionFormCount"
        :productData="selectedProduct.data"
        :formCount="formCount"
        :formData="selectedProduct.formData"
      ></component>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  ORDER_FORM_PROCESSOR_STATUS,
  PRODUCT_TYPES,
  RESULT_ORDER_ITEM_STATUS,
  LIST_FORM
} from "@/utils/constant";
import SelectOrderForm from "@/views/PrintOrderForm/SelectOrderForm.vue";
import appUtils from "@/utils/appUtils";

export default {
  name: "OrderForm",
  components: { SelectOrderForm },
  data() {
    return {
      orderItems: [],
      files: [],
      resultFiles: [],
      showModalRequisitionForm: false,
      createRequisitionFormCount: 1,
      showSelectOrderFormModal: false,
      selectingOrderFormCount: 1,
      selectedProduct: {},
      formCount: 0,
      currentForm: {},
      loading: false,
      LIST_FORM
    };
  },
  props: {
    orderDetail: Object,
    apiPayload: Array,
    isCancelOrder: Boolean,
    product: Object,
    index: Number,
    form_category: String,
    title: String,
    uploadedFiles: Array,
    disableCreateBtn: {
      type: Boolean,
      default: false
    },
    refId: String
  },
  computed: {
    ...mapState({
      orders: state => state.orders
    }),
    checkUploadFiles() {
      return this.resultFiles?.some(item => !item);
    },
    currentFormName() {
      return this.currentForm?.name?.toUpperCase() || "";
    }
  },
  async mounted() {
    this.orderItems = await this.makingOrderItems(this.orderDetail);
    this.files = this.orderItems?.map(item => null);
    this.resultFiles = this.orderItems?.map(item => null);
    this.handleMapData(this.orderItems);
  },
  watch: {
    orderDetail: {
      deep: true,
      async handler(data) {
        this.orderItems = await this.makingOrderItems(data);
        this.handleMapData(this.orderItems);
      }
    }
  },
  methods: {
    getUrlList(url_list) {
      return ["undefined", "object"].includes(typeof url_list) ||
        url_list === "" ||
        !this.isJSON(url_list)
        ? url_list
        : JSON.parse(url_list);
    },
    getProcessStatus(data) {
      if (!data?.order_processor) return;
      const { status } = data?.order_processor;
      switch (status) {
        case ORDER_FORM_PROCESSOR_STATUS.PENDING:
          return {
            color: "primary",
            title: this.$t("lbl_order_processor_pending")
          };
        case ORDER_FORM_PROCESSOR_STATUS.PROCESSING:
          return {
            color: "info",
            title: this.$t("lbl_order_processor_process")
          };
        case ORDER_FORM_PROCESSOR_STATUS.COMPLETE:
          return {
            color: "success",
            title: this.$t("lbl_order_processor_complete")
          };
        case ORDER_FORM_PROCESSOR_STATUS.CANCEL:
          return {
            color: "danger",
            title: this.$t("lbl_order_processor_cancel")
          };
        default:
          return;
      }
    },
    async makingOrderItems(data, form_type = null) {
      const newData = data?.order_items?.filter(
        item => item?.data?.type === PRODUCT_TYPES.SERVICE
      );
      const dataRaw = await Promise.all(
        newData?.map(async item => {
          const formDataItem = await this.handleGetFormData(item, form_type);
          return {
            ...item,
            formData: formDataItem
          };
        })
      );
      return dataRaw;
    },
    toggleFormSelectModal() {
      this.showSelectOrderFormModal = !this.showSelectOrderFormModal;
    },
    handleTriggerSelectOrderForm() {
      this.selectingOrderFormCount++;
    },
    async handleSelectOrderForm(form) {
      this.currentForm = form;
      this.orderItems = await this.makingOrderItems(
        this.orderDetail,
        this.currentForm.form_type
      );
      this.handleCreateRequisitionForm(this.orderItems[this.index]);
    },
    async handleCreateRequisitionForm(productInfo) {
      this.selectedProduct = { ...productInfo };
      this.showModalRequisitionForm = true;
    },
    closeModalRequisitionForm() {
      this.formCount++;
    },
    handleTriggerRequisitionForm() {
      this.createRequisitionFormCount++;
    },
    async handleSubmitRequisitionForm(formData) {
      if (!this.selectedProduct?.data?.id) return;

      if (this.createRequisitionFormCount) {
        const data = {
          ...formData,
          orderId: this.selectedProduct?.data?.id
        };
        this.$store.commit("orders/updateRequisitionFormV2", data);
        this.handleRefreshData(data);
        this.$router.replace({
          name: this.currentForm.print_preview_router,
          params: {
            id: this.selectedProduct?.data?.id
          }
        });
      }
    },
    async uploadFile(files, index) {
      try {
        this.loading = true;
        const formUrlArr = [...this.resultFiles];
        formUrlArr[index] = new Array(files.length);

        const promise_list = Array.from(files).map(async (file, fileIndex) => {
          const params = {
            file: file,
            FolderTarget: "OrderForm",
            IdTarget: this.orderItems[index].data.id
          };
          const formData = new FormData();

          for (const key in params) {
            formData.append(key, params[key]);
          }

          const res = await this.$rf
            .getRequest("CommonRequest")
            .uploadFileTenant(formData);
          formUrlArr[index][fileIndex] = res.data.fileUrl;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("lbl_upload_file_successfully"),
              icon: "CheckIcon",
              variant: "success"
            }
          });
        });

        Promise.all(promise_list).then(() => {
          this.resultFiles = formUrlArr;
          this.$emit("uploadFiles", this.resultFiles);
          this.loading = false;
        });
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("lbl_an_error_occurred_while_uploading"),
            icon: "AlertTriangleIcon",
            variant: "danger"
          }
        });
      }
    },
    getFileName(url) {
      if (!url) return "";
      const fileName =
        typeof url === "object"
          ? url.url.substring(url.url.lastIndexOf("/") + 1)
          : url.substring(url.lastIndexOf("/") + 1);
      return fileName;
    },
    handleMapData(data) {
      data?.forEach((item, index) => {
        const orderProcessor = item?.data?.order_processor || {};
        const dataSelected = this.apiPayload?.find(
          payloadItem => payloadItem?.order_item_id === item?.data?.id
        );
        if (orderProcessor) {
          switch (this.form_category) {
            case "orderFormUrls":
              this.resultFiles[index] = this.uploadedFiles.length
                ? this.uploadedFiles
                : dataSelected?.url_order_form ||
                  dataSelected?.url_order_form.length
                ? dataSelected?.url_order_form
                : orderProcessor.order_form_url ||
                  orderProcessor.order_form_url.length
                ? orderProcessor.order_form_url
                : [];
              break;
            case "otherFormUrls":
              this.resultFiles[index] = this.uploadedFiles.length
                ? this.uploadedFiles
                : dataSelected?.url_other_form.length
                ? dataSelected?.url_other_form?.map(item =>
                    item.url ? item.url : item
                  )
                : orderProcessor?.url_other_form
                    ?.filter(el => el.type === 2)
                    .map(item => (item.url ? item.url : item));
              break;
            default:
              break;
          }
        }
      });
    },
    checkDisabled(orderItem) {
      const processorStatus = orderItem?.data?.order_processor?.status;
      const isProcessOrSuccessProcessor = [
        ORDER_FORM_PROCESSOR_STATUS.PROCESSING,
        ORDER_FORM_PROCESSOR_STATUS.COMPLETE
      ].includes(processorStatus);

      const isSendedResult = orderItem?.data?.document_link?.every(
        item => item?.status === RESULT_ORDER_ITEM_STATUS.APPROVE
      );

      return {
        isDisabled:
          isProcessOrSuccessProcessor || isSendedResult || this.isCancelOrder,
        isSended: isSendedResult
      };
    },
    async handleChangeFormData(formData) {
      const productId = Number(this.selectedProduct.data.id);

      const params = {
        form_type: this.currentForm.form_type,
        edited_at: Date.now(),
        order_item_id: productId,
        content: JSON.stringify(formData)
      };

      try {
        await this.$rf.getRequest("CommonRequest").postDraftOrderForm(params);
      } catch (error) {
        console.log(error);
      } finally {
        this.showModalRequisitionForm = false;
      }
    },
    async handleGetFormData(orderItem, form_type) {
      if (
        !orderItem?.data?.id ||
        (!orderItem?.data?.consent_form_type?.[0] && !form_type)
      )
        return;
      try {
        const params = {
          order_item_id: orderItem.data.id,
          order_form_type: orderItem.data.consent_form_type[0] || form_type
        };

        const response = await this.$rf
          .getRequest("CommonRequest")
          .getDraftOrderForm(params);

        const responseData = response.data?.data;
        if (!responseData?.content) return;
        return JSON.parse(responseData.content);
      } catch (error) {
        console.log(error);
      }
    },
    async handleRefreshData(newFormData) {
      await this.handleChangeFormData(newFormData);
      this.$emit("refresh", { id: this.$route.params.id });
    },
    isJSON(data) {
      try {
        JSON.parse(data);
      } catch (error) {
        return false;
      }
      return true;
    },
    handleTriggerUploadFile() {
      const id = this.refId;
      console.log(this.$refs[`${id}`]);

      this.$refs[`${id}`].click();
    }
  }
};
</script>

<style lang="scss" scoped>
.save-btn {
  width: 50%;
  // transform: translate(50%, 100%);
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
}

.cs-box-item {
  border: 1px solid #20409b;
  padding: 12px;
  border-radius: 4px;
}

.cs-dropdown ::v-deep {
  button {
    background-color: transparent !important;
    padding: 0 !important;
    border: none !important;

    &:hover {
      background-color: transparent !important;
      padding: 0 !important;
      border: none !important;
      box-shadow: none !important;
    }

    &::after {
      display: none !important;
    }
  }
}
</style>
