<template>
  <div class="rounded bg-white p-1 mb-2 h-100">
    <div
      class="product-name mb-50 d-flex align-items-center justify-content-between"
    >
      <span class="mr-50">
        {{
          currLanguage == "en"
            ? getProductNameEn(orderItem.data.product_name_multilanguages) ||
              orderItem.data.product_name
            : orderItem.data.product_name
        }}
      </span>
    </div>

    <div class="flex flex-col" style="gap: 16px">
      <template v-if="orderItem.indication_order">
        <SubmitFormItemIndicator
          :orderDetail="orderDetail"
          :itemID="orderItem.data.id"
        />
      </template>
      <template v-if="formList && formList.length">
        <SubmitFormItemSection
          v-for="form in formList"
          :key="form.id"
          :form="form"
          :orderItem="orderItem"
          :isCancelOrder="isCancelOrder"
          :apiPayload="apiPayload"
          @upload="
            (url_list) =>
              $emit('upload', { url_list, form_type: form.form_type })
          "
          @create="$emit('create', form)"
        />
      </template>
      <p
        v-if="!orderItem.indication_order && (!formList || !formList.length)"
        style="margin: 30px auto"
      >
        {{ $t("lbl_no_required_attachments_for_this_product_") }}
      </p>
    </div>
  </div>
</template>

<script>
import SubmitFormItemSection from "./SubmitFormItemSection.vue";
import SubmitFormItemIndicator from "./SubmitFormItemIndicator.vue";
import store from "@/store";

export default {
  name: "SubmitFormItem",
  components: { SubmitFormItemSection, SubmitFormItemIndicator },
  props: {
    orderItem: {
      type: Object,
      default: () => {},
    },
    apiPayload: {
      type: Object,
      default: () => {},
    },
    orderDetail: {},
    isCancelOrder: Boolean,
  },
  computed: {
    formList() {
      return this.orderItem.consent_form && this.orderItem.consent_form.length
        ? [...this.orderItem.consent_form].sort(
            (a, b) => a.form_type - b.form_type
          )
        : [];
    },
  },
  data() {
    return {
      currLanguage: store.state.language.currLanguage,
    };
  },
  methods: {
    getProductNameEn(arr) {
      return arr?.find((elm) => elm.language == "en")?.name;
    },
  },
  watch: {
    "$store.state.language.currLanguage": function () {
      this.currLanguage = this.$store.state.language.currLanguage;
    },
  },
};
</script>

<style lang="scss" scoped>
.product-name {
  font-weight: 600;
}
</style>