<template>
  <div>
    <span class="title-form">Select Order Form</span>
    <div class="mt-3">
      <div id="printOrderForm" class="mt-3">
        <!-- PAGE 1 -->
        <div>
          <div>
            <!-- PATIENT INFORMATION -->
            <div>
              <span class="cs-title">CHOOSE ORDER FORM TYPE</span>
              <div class="cs-flex">
                <span class="cs-label">List order form type</span>
                <!-- <b-form-input class="cs-content" v-model="form.product_name" /> -->
                <b-dropdown
                  :text="form.name || 'Chọn loại phiếu'"
                  block
                  split
                  split-variant="outline-primary px-50"
                  variant="primary"
                  class="w-100"
                  menu-class="w-100"
                  split-class="dropdown"
                  ref="dropdownOrderForms"
                  :disabled="disabled"
                  @click="handleDropdownClicked()"
                >
                  <b-dropdown-item
                    v-for="form in list_form"
                    :key="form.form_type"
                    @click="handleSelectedForm(form)"
                  >{{ form.name }}</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <!-- PATIENT INFORMATION -->
          </div>
        </div>
        <!-- PAGE 1 -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "SelectOrderForm",
  props: {
    // productData: Object,
    selectingOrderFormCount: Number,
    formCount: Number,
    list_form: Array,
    disabled: Boolean,
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    ...mapState({
      orders: state => state.orders
    })
  },
  watch: {
    selectingOrderFormCount() {
      this.handleCreateConsentForm();
    },
    formCount(count) {
      if (!count) return;
      this.handleGetFormData();
    },
    orders: {
      deep: true,
      handler(data) {
        if (data?.viewDetail) {
          this.handleMapData(data?.viewDetail);
        }
      }
    },
    
  },
  mounted() {
    this.handleMapData();
  },
  methods: {
    handleSelectedForm(form) {
      this.form = form
    },
    handleDropdownClicked() {
      const dropdown = this.$refs.dropdownOrderForms;
      setTimeout(() => {
        if (dropdown.visible) {
          dropdown.hide();
        } else dropdown.show();
      }, 0);
    },
    handleCreateConsentForm() {
      if (this.selectingOrderFormCount) {
        if (this.form?.form_type) this.$emit("onCreate", this.form);
        else this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Bạn phải chọn loại phiếu để chỉnh sửa và in"),
            icon: "AlertTriangleIcon",
            variant: "danger"
          }
        });
      } else {
        this.form = {};
      }
    },
    handleMapData() {
    //   const patientInfo =
    //     this.orders.viewDetail?.order_contact?.find(item => item?.type === 2) ||
    //     this.orders.viewDetail?.order_contact?.find(item => item?.type === 1);
    //   this.form.full_name = patientInfo?.name;
    //   this.form.product_name = this.productData.product_name
    //   this.form.full_name = this.formData?.full_name || patientInfo?.name;
    },
    handleGetFormData() {
      this.$emit("onChangeFormData", this.form);
    }
  }
};
</script>

<style lang="scss" scoped>
.title-form {
  font-size: 24px;
  color: black;
  text-align: center;
}

.cs-container {
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;

  * {
    color: black;
    font-family: "Times New Roman", "Tinos", Times, serif !important;
  }

  #printOrderForm {
    margin: auto;
    // margin-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
  }

  h2 {
    // color: black;
    font-size: 28px;
  }
}

.container-border {
  border: 1px solid #5c5c5c;
  padding: 0;
  width: 50%;
}

.cs-title {
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 6px;
}

.cs-flex {
  display: flex;
  gap: 8px;
  padding: 6px;
  box-sizing: border-box;
  align-items: center;
}

.cs-label {
  width: 150px;
}

.cs-width-full {
  width: 100% !important;
}

.cs-font-bold {
  font-weight: bold;
}

.cs-checkbox {
  display: flex;
  align-items: center;

  input {
    width: 18px;
    height: 18px;
  }

  input[type="checkbox"] {
    accent-color: #20419b;
  }

  label {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-left: 6px;
  }
}

.cs-content {
  flex: 1;
}

.min-checkbox {
  min-width: 120px;
}
.box-content {
  display: block;
  padding: 6px;
  min-height: 120px;
}
.html2pdf__page-break {
  page-break-before: always;
}

.cs-padding {
  padding: 6px;
}

.cs-border {
  border: 1px solid #5c5c5c;
}

.content-none {
  width: 100%;
  border-bottom: 2px dashed;
}

.cs-text-sm {
  font-size: 11px;
  font-weight: 500;
}

.cs-text-md {
  font-size: 16px;
}

.cs-list {
  display: flex;
  gap: 12px;
}

.cs-ol {
  li {
    position: relative;
    padding-left: 10px;
    margin-left: 15px;
  }

  li:before {
    position: absolute;
    left: -2px;
  }
}

.cs-none {
  pointer-events: none;
}
</style>