<template>
  <div class="py-1">
    <h5 class="d-flex align-items-center mb-0">
      <feather-icon icon="DollarSignIcon" size="24" class="text-success" />
      <span class="mr-50">{{ $t('lbl_payment_methods') }}:</span>
      <span class="text-primary">{{ $t('lbl_cash') }}</span>
    </h5>
  </div>
</template>

<script>
export default {
  name: "OrderPaymentInformation"
};
</script>

<style>
</style>
