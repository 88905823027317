<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.0212 19.5875L14.0329 4.75271C13.4667 3.70092 11.9584 3.70092 11.3917 4.75271L3.40386 19.5875C3.28092 19.8159 3.21929 20.0722 3.22497 20.3314C3.23065 20.5907 3.30346 20.8441 3.43629 21.0668C3.56911 21.2895 3.75742 21.474 3.98282 21.6023C4.20822 21.7305 4.46303 21.7981 4.72235 21.7985H20.7004C20.9599 21.7985 21.215 21.7312 21.4407 21.6031C21.6664 21.4751 21.8551 21.2906 21.9882 21.0678C22.1213 20.845 22.1943 20.5915 22.2001 20.332C22.2059 20.0726 22.1442 19.816 22.0212 19.5875ZM12.7125 19.5018C12.5271 19.5018 12.3459 19.4468 12.1917 19.3438C12.0376 19.2408 11.9174 19.0944 11.8465 18.9231C11.7755 18.7518 11.7569 18.5633 11.7931 18.3814C11.8293 18.1996 11.9186 18.0326 12.0497 17.9015C12.1808 17.7704 12.3478 17.6811 12.5296 17.6449C12.7115 17.6087 12.9 17.6273 13.0713 17.6983C13.2426 17.7692 13.389 17.8894 13.492 18.0435C13.595 18.1977 13.65 18.3789 13.65 18.5643C13.65 18.6874 13.6257 18.8093 13.5786 18.9231C13.5315 19.0368 13.4624 19.1401 13.3754 19.2272C13.2883 19.3142 13.185 19.3833 13.0713 19.4304C12.9575 19.4775 12.8356 19.5018 12.7125 19.5018ZM13.7306 10.0736L13.4615 15.7919C13.4615 15.9908 13.3825 16.1815 13.2419 16.3222C13.1012 16.4628 12.9105 16.5418 12.7116 16.5418C12.5127 16.5418 12.3219 16.4628 12.1813 16.3222C12.0407 16.1815 11.9616 15.9908 11.9616 15.7919L11.6926 10.0759C11.6866 9.93932 11.7081 9.80291 11.7559 9.67482C11.8037 9.54672 11.8768 9.42956 11.9708 9.33031C12.0648 9.23107 12.1779 9.15177 12.3032 9.09715C12.4286 9.04253 12.5636 9.01371 12.7003 9.01239H12.7102C12.8478 9.01232 12.9841 9.04014 13.1107 9.09417C13.2373 9.1482 13.3516 9.22731 13.4468 9.32675C13.542 9.42618 13.616 9.54387 13.6645 9.67271C13.7129 9.80154 13.7348 9.93886 13.7287 10.0764L13.7306 10.0736Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: String
  }
};
</script>

<style>
</style>