<template>
  <div>
    <div class="rounded bg-white p-1 mb-2">
      <div
        :class="{
          'h-100': orderItems.length === 1,
          'py-2': true,
          'border-bottom': true,
        }"
        v-for="(item, index) in orderItems"
        :key="item.data.id"
      >
        <div class="product-name mb-50 d-flex align-items-center">
          <span class="mr-50">
            {{
            currLanguage == "en"
            ? getProductNameEn(item.data.product_name_multilanguages) ||
            item.data.product_name
            : item.data.product_name
            }}
          </span>
        </div>
        <div
          class="d-inline-block"
          style="width: 100%"
          tabindex="0"
          v-b-tooltip.top
          :title="
            $t(
              checkDisabled(item).isDisabled
                ? isCancelOrder
                  ? $t('lbl_assign_processor.fm_cannot_change_processor', [
                      $t('lbl_assign_processor.lbl_canceled'),
                    ])
                  : checkDisabled(item).isSended
                  ? $t('lbl_assign_processor.fm_cannot_change_processor', [
                      $t('lbl_assign_processor.lbl_result_received'),
                    ])
                  : $t('lbl_assign_processor.fm_cannot_change_processor', [
                      $t('lbl_assign_processor.lbl_is_processing'),
                    ])
                : ''
            )
          "
        >
          <b-dropdown
            :text="
              getProcessorName(item) ||
              $t('lbl_assign_processor.lbl_choose_processor')
            "
            block
            split
            split-variant="outline-primary px-50"
            variant="primary"
            class="mb-1"
            menu-class="w-100"
            split-class="dropdown"
            ref="dropdownProcessor"
            :disabled="checkDisabled(item).isDisabled"
            @click="handleDropdownClicked(index)"
          >
            <b-dropdown-item
              v-for="processor in processors"
              :key="processor.id"
              @click="handleSelectedProcessor(item, processor)"
            >{{ processor.org_info.name }}</b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="shadow-none mb-1" :set="(process = getProcessStatus(item.data))">
          <div class="d-flex align-items-center">
            <span style="font-size: 18px">{{ $t("lbl_assign_processor.lbl_status") }}:</span>
            <div class="ml-2">
              <b-badge
                class
                v-if="process.order_processor"
                :variant="process.color"
              >{{ process.title }}</b-badge>
              <span class="italic ml-1">{{ process.time }}</span>
            </div>
          </div>
          <b-card class="mt-1 p-1" bg-variant="light" no-body v-if="checkProcessorRejected(item)">
            <div class="italic">
              <span class="mr-1">{{ $t("lbl_assign_processor.lbl_reason") }}:</span>
              <span>{{ getRejectReason(item) }}</span>
            </div>
          </b-card>
        </div>
        <div class="shadow-none mb-1">
          <span style="font-size: 18px">{{ $t("lbl_assign_processor.lbl_attachment_list") }}:</span>
          <div>
            <b-link
              v-for="(url, i) in attachment_list[index]"
              :key="url + i"
              :href="url"
              target="_blank"
              class="mr-1 d-block"
              style="margin-top: 5px"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 10H10C10.2652 10 10.5196 9.89464 10.7071 9.70711C10.8946 9.51957 11 9.26522 11 9C11 8.73478 10.8946 8.48043 10.7071 8.29289C10.5196 8.10536 10.2652 8 10 8H9C8.73478 8 8.48043 8.10536 8.29289 8.29289C8.10536 8.48043 8 8.73478 8 9C8 9.26522 8.10536 9.51957 8.29289 9.70711C8.48043 9.89464 8.73478 10 9 10ZM9 12C8.73478 12 8.48043 12.1054 8.29289 12.2929C8.10536 12.4804 8 12.7348 8 13C8 13.2652 8.10536 13.5196 8.29289 13.7071C8.48043 13.8946 8.73478 14 9 14H15C15.2652 14 15.5196 13.8946 15.7071 13.7071C15.8946 13.5196 16 13.2652 16 13C16 12.7348 15.8946 12.4804 15.7071 12.2929C15.5196 12.1054 15.2652 12 15 12H9ZM20 8.94C19.9896 8.84813 19.9695 8.75763 19.94 8.67V8.58C19.8919 8.47718 19.8278 8.38267 19.75 8.3L13.75 2.3C13.6673 2.22222 13.5728 2.15808 13.47 2.11C13.4402 2.10576 13.4099 2.10576 13.38 2.11C13.2784 2.05174 13.1662 2.01434 13.05 2H7C6.20435 2 5.44129 2.31607 4.87868 2.87868C4.31607 3.44129 4 4.20435 4 5V19C4 19.7956 4.31607 20.5587 4.87868 21.1213C5.44129 21.6839 6.20435 22 7 22H17C17.7956 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7956 20 19V9C20 9 20 9 20 8.94ZM14 5.41L16.59 8H15C14.7348 8 14.4804 7.89464 14.2929 7.70711C14.1054 7.51957 14 7.26522 14 7V5.41ZM18 19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H7C6.73478 20 6.48043 19.8946 6.29289 19.7071C6.10536 19.5196 6 19.2652 6 19V5C6 4.73478 6.10536 4.48043 6.29289 4.29289C6.48043 4.10536 6.73478 4 7 4H12V7C12 7.79565 12.3161 8.55871 12.8787 9.12132C13.4413 9.68393 14.2044 10 15 10H18V19ZM15 16H9C8.73478 16 8.48043 16.1054 8.29289 16.2929C8.10536 16.4804 8 16.7348 8 17C8 17.2652 8.10536 17.5196 8.29289 17.7071C8.48043 17.8946 8.73478 18 9 18H15C15.2652 18 15.5196 17.8946 15.7071 17.7071C15.8946 17.5196 16 17.2652 16 17C16 16.7348 15.8946 16.4804 15.7071 16.2929C15.5196 16.1054 15.2652 16 15 16Z"
                  fill="#20419b"
                />
              </svg>
              {{ getFileName(url) }}
            </b-link>
          </div>
        </div>
        <div class="shadow-none mb-1 d-flex align-items-center">
          <span style="font-size: 18px">{{ $t("lbl_result_date") }}:</span>
          <div class="ml-2 d-flex" style="align-items: end; gap: 5px">
            <span
              v-if="item.data.return_date"
              class="italic ml-1"
            >{{ appUtils.formatDate(item.data.return_date) }}</span>
            <WarningIcon
              v-if="
                getResultDateInfor(
                  item.data.return_date,
                  item.data.order_processor && item.data.order_processor.status
                ).isShowBadge
              "
              :fill="getResultDateInfor(item.data.return_date).variant"
              :title="getResultDateInfor(item.data.return_date).tooltip"
              v-b-tooltip.top
            ></WarningIcon>
          </div>
        </div>
        <div>
          <div style="font-size: 18px">{{ $t("lbl_assign_processor.lbl_result") }}:</div>
        </div>
        <b-card
          no-body
          bg-variant="light"
          class="shadow-none p-1"
          :class="
            index < item.order_item_attachments.length - 1 ? 'mb-1' : 'mb-0'
          "
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="mr-auto">
              <div class="font-weight-bold">
                {{
                currLanguage == "en"
                ? getTitleAttachmentEn(
                item.order_item_attachments[0].OrderItemAttachmentsMultilanguage
                ) || item.order_item_attachments[0].title
                : item.order_item_attachments[0].title
                }}
              </div>
              <div
                v-if="item.order_item_attachments[0].url && item.order_item_attachments[0].complete_date"
                class="font-italic"
                style="font-size: 11px"
              >
                ({{ $t("lbl_submit_results") }}:
                {{
                item.order_item_attachments[0].complete_date &&
                appUtils.formatDate(item.order_item_attachments[0].complete_date)
                }})
              </div>
            </div>
            <div v-if="item.order_item_attachments[0].url" class="d-flex justify-content-end ml-50">
              <div
                class="text-primary"
                role="button"
                @click="handleClickFile(item.order_item_attachments[0])"
              >{{ $t("lbl_assign_processor.lbl_view_result") }}</div>
              <div v-if="item.order_item_attachments[0].status === 1" class="ml-75">
                <b-badge :variant="'success'">{{ $t("FINISHED") }}</b-badge>
              </div>
            </div>
          </div>
        </b-card>
      </div>

      <div
        class="rounded bg-white pt-2 text-center flex items-center justify-center"
        v-if="!isPending"
        style="gap: 8px"
      >
        <b-button
          class="m-0"
          variant="primary"
          @click="handleClickSendBtn()"
          v-b-modal.my-modal
          :disabled="isLoading || isPending || isCancelOrder"
        >{{ $t("lbl_assign_processor.lbl_send_data_to_processor") }}</b-button>
      </div>
    </div>

    <StepNavigator
      :disabledNext="checkProcessorReject"
      @back="$emit('onChangeStep', 0)"
      @next="$emit('onChangeStep', 2)"
    />

    <b-modal
      id="modal-select-files"
      size="lg"
      scrollable
      hide-header
      :ok-title="$t('lbl_assign_processor.lbl_send_attachments')"
      :cancel-title="$t('lbl_cancel')"
      @ok="save"
      @hide="$bvModal.hide('modal-select-files')"
    >
      <SelectFilesToSend :order_items_list_files="order_items_list_files" />
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import appUtils from '@/utils/appUtils'
import {
  PRODUCT_TYPES,
  RESULT_ORDER_ITEM_STATUS,
  SERVICE_GROUP_TYPE
} from '@/utils/constant'
import { ORDER_FORM_PROCESSOR_STATUS } from '@/utils/constant'
import SelectFilesToSend from '@/views/Orders/Detail/Components/SelectFilesToSendModal.vue'
import WarningIcon from '@/components/WarningIcon.vue'
import StepNavigator from './StepNavigator.vue'

export default {
  name: 'AssignProcessor',
  components: {
    ToastificationContent,
    SelectFilesToSend,
    WarningIcon,
    StepNavigator
  },
  data() {
    return {
      isLoading: true,
      orderItems: [],
      processors: [],
      order_items_list_files: [],
      appUtils,
      ORDER_FORM_PROCESSOR_STATUS,
      currLanguage: this.$store.state.language.currLanguage
    }
  },
  props: {
    orderDetail: {
      type: Object,
      default: () => {}
    },
    apiPayload: {
      type: Array,
      default: () => []
    },
    isCancelOrder: Boolean,
    checkProcessorReject: Boolean
  },
  computed: {
    isPending() {
      const isBoolData = [
        ORDER_FORM_PROCESSOR_STATUS.PROCESSING,
        ORDER_FORM_PROCESSOR_STATUS.COMPLETE
      ].some(el =>
        this.orderItems
          ?.map(item => item?.data?.order_processor?.status)
          .includes(el)
      )
      return isBoolData
    },
    attachment_list() {
      let attachment_list = []
      this.order_items_list_files.forEach(el => {
        let arr = [
          ...el.concent_forms,
          ...el.requisition_forms,
          ...el.order_forms,
          ...el.other_forms
        ]
          .filter(file => file.canProcessorView === 'true')
          .map(file => file.url)
        attachment_list.push(arr)
      })
      return attachment_list
    }
  },
  created() {
    // await this.handleGetProcessor()
  },
  async mounted() {
    await this.handleGetProcessor()
    this.orderItems = this.orderItems
      ?.map(elm => {
        return {
          ...elm,
          processorSelected: this.processors?.find(
            item => item.id === elm.data?.order_processor?.processor_id
          )
        }
      })
      ?.filter(
        item =>
          item?.service_catalog?.service_category?.service_group_category_id !==
          SERVICE_GROUP_TYPE.patientVisit
      )
  },
  watch: {
    orderDetail: {
      handler(data) {
        this.orderItems = this.makingOrderItems(data, this.orderItems)
        this.order_items_list_files = this.makingOrderItemsListFiles()
      },
      deep: true
    },
    async isLoading(data) {
      if (!data) {
        this.makingAssignProcessor()
      }
    },
    '$store.state.language.currLanguage': function() {
      this.currLanguage = this.$store.state.language.currLanguage
    }
  },
  async beforeMount() {
    this.$emit('refresh', { id: this.orderDetail?.id })
    this.orderItems = this.makingOrderItems(this.orderDetail)
    this.order_items_list_files = this.makingOrderItemsListFiles()
  },
  methods: {
    getProcessStatus(data) {
      if (!data?.order_processor) return {}
      const { status, processor_states } = data?.order_processor
      const timeStamp = processor_states?.find(item => item.status === status)
        ?.time_changed
      const time = moment(timeStamp).format('HH:mm-DD/MM/YYYY')

      switch (status) {
        case ORDER_FORM_PROCESSOR_STATUS.PENDING:
          return {
            color: 'primary',
            title: this.$t('lbl_order_processor_pending'),
            time
          }
        case ORDER_FORM_PROCESSOR_STATUS.PROCESSING:
          return {
            color: 'info',
            title: this.$t('lbl_order_processor_process'),
            time
          }
        case ORDER_FORM_PROCESSOR_STATUS.COMPLETE:
          return {
            color: 'success',
            title: this.$t('lbl_order_processor_complete'),
            time
          }
        case ORDER_FORM_PROCESSOR_STATUS.CANCEL:
          return {
            color: 'danger',
            title: this.$t('lbl_order_processor_cancel'),
            time
          }
        default:
          return {}
      }
    },
    getResultDateInfor(return_date, order_processor_status = 0) {
      const time = return_date - Date.now()
      if (
        !return_date ||
        [
          ORDER_FORM_PROCESSOR_STATUS.COMPLETE,
          ORDER_FORM_PROCESSOR_STATUS.CANCEL,
          ORDER_FORM_PROCESSOR_STATUS.SUPPLIER_CANCEL
        ].includes(order_processor_status)
      ) {
        return {
          isShowBadge: false
        }
      } else if (time < -86400000) {
        return {
          isShowBadge: true,
          variant: 'red',
          tooltip: this.$t('lbl_the_deadline_for_returning_results_has_passed')
        }
      } else if (time < 0) {
        return {
          isShowBadge: true,
          variant: 'red',
          tooltip: this.$t('lbl_results_are_due_today')
        }
      } else if (time < 86400000 * 3) {
        return {
          isShowBadge: true,
          variant: '#ffc107',
          tooltip: this.$t(
            'lbl_there_is_1_day_left_until_the_appointment_to_return_the_results',
            [Math.ceil(time / 86400000)]
          )
        }
      } else {
        return {
          isShowBadge: false
        }
      }
    },
    makingOrderItems(data, items) {
      const serviceItems = data?.order_items?.filter(
        item => item?.data?.type === PRODUCT_TYPES.SERVICE
      )
      let returnItems = []
      if (!items?.length) {
        returnItems = serviceItems
      } else {
        const newItems = serviceItems.map(service => {
          const processorInfo = data?.order_items.find(
            item => item?.data?.id === service?.data?.id
          )
          const processor = this.processors.find(
            processor =>
              processor.id === processorInfo.data.order_processor?.processor_id
          )
          return {
            ...service,
            processorSelected: { ...processor }
          }
        })
        returnItems = newItems
      }

      return returnItems?.filter(
        item =>
          item?.service_catalog?.service_category?.service_group_category_id !==
          SERVICE_GROUP_TYPE.patientVisit
      )
    },
    makingOrderItemsListFiles() {
      let order_items = this.orderItems.map((el, i) => {
        const apiPayload = this.apiPayload[i]
        return {
          test_name: el.data.product_name,
          concent_forms: apiPayload.url_concent_form?.map(url => {
            return url.hasOwnProperty('canProcessorView')
              ? url
              : {
                  url: url,
                  canProcessorView: false
                }
          }),
          order_forms: apiPayload.url_order_form?.map(url => {
            return url.hasOwnProperty('canProcessorView')
              ? url
              : {
                  url: url,
                  canProcessorView: false
                }
          }),
          other_forms: apiPayload.url_other_form?.map(url => {
            return url.hasOwnProperty('canProcessorView')
              ? url
              : {
                  url: url,
                  canProcessorView: false
                }
          }),
          requisition_forms: apiPayload.url_requisition_form?.map(url => {
            return url.hasOwnProperty('canProcessorView')
              ? url
              : {
                  url: url,
                  canProcessorView: false
                }
          })
        }
      })
      return order_items
    },
    makingAssignProcessor() {
      this.orderItems?.map(order => {
        this.$set(order, 'consentForm', null)
        this.$set(order, 'url_concent_form', null)
        this.$set(order, 'url_requisition_form', null)
      })
    },
    getProcessorName(item) {
      return item.hasOwnProperty('processorSelected')
        ? item.processorSelected?.org_info?.name
        : item.org_info?.name
    },
    handleClickSendBtn() {
      const isProcessorSelected = this.orderItems?.filter(
        el => el.processorSelected?.id
      )
      if (isProcessorSelected) {
        let attachmentFiles = []
        const list_key = [
          'url_requisition_form',
          'url_other_form',
          'url_order_form',
          'url_concent_form'
        ]
        this.apiPayload.forEach(item => {
          list_key.forEach(form_type => {
            if (Array.isArray(item[form_type])) {
              attachmentFiles = [...attachmentFiles, ...item[form_type]]
            } else if (Array.isArray(JSON.parse(item[form_type]))) {
              attachmentFiles = [
                ...attachmentFiles,
                ...JSON.parse(item[form_type])
              ]
            }
          })
        })
        if (attachmentFiles && attachmentFiles.length) {
          this.$bvModal.show('modal-select-files')
        } else {
          this.save()
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('lbl_assign_processor.lbl_please_choose_processor'),
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
      }
    },
    async save() {
      console.log(this.apiPayload)
      let payload = this.apiPayload.map((el, index) => {
        el.processor_id = this.orderItems.find(
          order => order?.data?.id == el.order_item_id
        )?.processorSelected?.id
        el.partner_product_id = this.orderItems.find(
          order => order?.data?.id == el.order_item_id
        )?.data?.product_id
        const requisition_form_type_1 =
          el.url_requisition_form?.map((url, urlIndex) => {
            if (typeof url === 'string')
              return {
                type: 1,
                title: 'requisition form',
                url: url,
                canProcessorView: this.order_items_list_files[index]
                  .requisition_forms[urlIndex].canProcessorView
                  ? 'true'
                  : 'false'
              }
            else
              return {
                type: 1,
                title: 'requisition form',
                url: url.url,
                canProcessorView: this.order_items_list_files[index]
                  .requisition_forms[urlIndex].canProcessorView
                  ? 'true'
                  : 'false'
              }
          }) || []
        const requisition_form_type_2 =
          el.url_other_form?.map((url, urlIndex) => {
            if (typeof url === 'string')
              return {
                type: 2,
                title: 'other form',
                url: url,
                canProcessorView: this.order_items_list_files[index]
                  .other_forms[urlIndex].canProcessorView
                  ? 'true'
                  : 'false'
              }
            else
              return {
                type: 2,
                title: 'other form',
                url: url.url,
                canProcessorView: this.order_items_list_files[index]
                  .other_forms[urlIndex].canProcessorView
                  ? 'true'
                  : 'false'
              }
          }) || []
        const requisition_form = [
          ...requisition_form_type_1,
          ...requisition_form_type_2
        ]
        delete el.url_requisition_form
        delete el.url_other_form
        let url_order_form = el.url_order_form.map((url, urlIndex) => {
          return url.hasOwnProperty('canProcessorView')
            ? url
            : {
                url: url,
                canProcessorView: this.order_items_list_files[index]
                  .order_forms[urlIndex].canProcessorView
                  ? 'true'
                  : 'false'
              }
        })
        let url_concent_form = el.url_concent_form.map((url, urlIndex) => {
          return url.hasOwnProperty('canProcessorView')
            ? url
            : {
                url: url,
                canProcessorView: this.order_items_list_files[index]
                  .concent_forms[urlIndex].canProcessorView
                  ? 'true'
                  : 'false'
              }
        })
        return {
          ...el,
          order_item_attachment_ids: [
            this.orderItems
              ?.find(orderItem => orderItem.data.id === el.order_item_id)
              ?.order_item_attachments?.map(item => item.id)[0]
          ],
          url_order_form:
            typeof el.url_order_form === 'object'
              ? JSON.stringify(url_order_form)
              : url_order_form,
          url_concent_form:
            typeof el.url_concent_form === 'object'
              ? JSON.stringify(url_concent_form)
              : url_concent_form,
          requisition_form: requisition_form,
          assign_date: Date.now()
        }
      })
      payload = payload?.filter(elm => elm.processor_id)

      this.isLoading = true
      await this.$rf
        .getRequest('CommonRequest')
        .postOrderToAssignedProcessor({
          data: payload.filter(elm => elm.processor_id)
        })
        .then(response => {
          this.$store.commit('orders/updatePayloadOrders', [])

          this.$emit('refresh', { id: this.orderDetail?.id })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t(
                'lbl_assign_processor.lbl_you_have_successfully_sent_data'
              ),
              icon: 'CheckIcon',
              variant: 'success'
            }
          })
        })
        .catch(error => {
          if (error == 400002) {
            console.log(error)
          }
        })
      this.isLoading = false
    },
    handleDropdownClicked(index) {
      const dropdown = this.$refs.dropdownProcessor[index]
      setTimeout(() => {
        if (dropdown.visible) {
          dropdown.hide()
        } else dropdown.show()
      }, 0)
    },
    handleSelectedProcessor(orderItem, processor) {
      orderItem.processorSelected = { ...processor }
    },
    async handleGetProcessor() {
      const userData = appUtils.getLocalUser()
      if (!userData?.supplierInfo?.id) return

      const params = {
        page_size: 100,
        page_num: 1,
        supplier_id: userData.supplierInfo.id
      }

      const res = await this.$rf
        .getRequest('CommonRequest')
        .getProcessors(params)
        .then(response => {
          this.processors = response.data.data
          this.isLoading = false
          return response.data.data
        })
        .catch(error => console.log(error))
      return res
    },
    checkDisabled(orderItem) {
      const processorStatus = orderItem?.data?.order_processor?.status
      const isProcessOrSuccessProcessor = [
        ORDER_FORM_PROCESSOR_STATUS.PROCESSING,
        ORDER_FORM_PROCESSOR_STATUS.COMPLETE
      ].includes(processorStatus)

      const isSendedResult = orderItem?.data?.document_link?.every(
        item => item?.status === RESULT_ORDER_ITEM_STATUS.APPROVE
      )

      return {
        isDisabled:
          isProcessOrSuccessProcessor || isSendedResult || this.isCancelOrder,
        isSended: isSendedResult
      }
    },
    getProduct(data) {
      return data?.data
    },
    handleClickFile(selectedFile) {
      const url = selectedFile?.url
      if (!url) return
      window.open(url, '_blank')
    },
    checkProcessorRejected(orderItem) {
      return (
        orderItem?.data?.order_processor?.status === 4 &&
        orderItem?.data?.order_processor?.reason
      )
    },
    getRejectReason(orderItem) {
      return orderItem?.data?.order_processor?.reason
    },
    getFileName(url) {
      if (!url) return ''
      const fileName =
        typeof url === 'object'
          ? url.url.substring(url.url.lastIndexOf('/') + 1)
          : url.substring(url.lastIndexOf('/') + 1)
      return fileName
    },
    getTitleAttachmentEn(arr) {
      return arr?.find(elm => elm.language == 'en')?.title
    },
    getProductNameEn(arr) {
      return arr?.find(elm => elm.language == 'en')?.name
    }
    // formatDate(time, format = "DD/MM/YYYY") {
    //   if (!time) return ""
    //   const date = moment.utc(time).format();
    //   return moment
    //     .utc(date)
    //     .local()
    //     .format(format);
    // },
  }
}
</script>

<style lang="scss" scoped>
.product-name {
  font-weight: 600;
}
.description {
  font-size: 12px;
  a {
    text-decoration: underline;
  }
}
.save-btn {
  width: 50%;
  transform: translate(50%, 100%);
}

.cs-card .card-body {
  padding: 8px;
}
</style>
