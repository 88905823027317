<template>
  <div
    class="rounded bg-white p-1 text-center flex items-center justify-between"
    style="gap: 8px"
  >
    <b-button 
      v-if="!disabledBack"
      variant="outline-primary"
      @click="$emit('back')"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.4599 8.28994C11.367 8.19621 11.2564 8.12182 11.1345 8.07105C11.0127 8.02028 10.882 7.99414 10.7499 7.99414C10.6179 7.99414 10.4872 8.02028 10.3654 8.07105C10.2435 8.12182 10.1329 8.19621 10.0399 8.28994L7.03994 11.2899C6.94621 11.3829 6.87182 11.4935 6.82105 11.6154C6.77028 11.7372 6.74414 11.8679 6.74414 11.9999C6.74414 12.132 6.77028 12.2627 6.82105 12.3845C6.87182 12.5064 6.94621 12.617 7.03994 12.7099L10.0399 15.7099C10.1329 15.8037 10.2435 15.8781 10.3654 15.9288C10.4872 15.9796 10.6179 16.0057 10.7499 16.0057C10.882 16.0057 11.0127 15.9796 11.1345 15.9288C11.2564 15.8781 11.367 15.8037 11.4599 15.7099C11.5537 15.617 11.6281 15.5064 11.6788 15.3845C11.7296 15.2627 11.7557 15.132 11.7557 14.9999C11.7557 14.8679 11.7296 14.7372 11.6788 14.6154C11.6281 14.4935 11.5537 14.3829 11.4599 14.2899L9.15994 11.9999L11.4599 9.70994C11.5537 9.61698 11.6281 9.50638 11.6788 9.38452C11.7296 9.26266 11.7557 9.13195 11.7557 8.99994C11.7557 8.86793 11.7296 8.73722 11.6788 8.61536C11.6281 8.4935 11.5537 8.3829 11.4599 8.28994ZM14.6599 11.9999L16.9999 9.70994C17.1882 9.52164 17.294 9.26624 17.294 8.99994C17.294 8.73364 17.1882 8.47824 16.9999 8.28994C16.8116 8.10164 16.5562 7.99585 16.2899 7.99585C16.0236 7.99585 15.7682 8.10164 15.5799 8.28994L12.5799 11.2899C12.4862 11.3829 12.4118 11.4935 12.361 11.6154C12.3103 11.7372 12.2841 11.8679 12.2841 11.9999C12.2841 12.132 12.3103 12.2627 12.361 12.3845C12.4118 12.5064 12.4862 12.617 12.5799 12.7099L15.5799 15.7099C15.6729 15.8037 15.7835 15.8781 15.9054 15.9288C16.0272 15.9796 16.1579 16.0057 16.2899 16.0057C16.422 16.0057 16.5527 15.9796 16.6745 15.9288C16.7964 15.8781 16.907 15.8037 16.9999 15.7099C17.0937 15.617 17.1681 15.5064 17.2188 15.3845C17.2696 15.2627 17.2957 15.132 17.2957 14.9999C17.2957 14.8679 17.2696 14.7372 17.2188 14.6154C17.1681 14.4935 17.0937 14.3829 16.9999 14.2899L14.6599 11.9999Z"
          fill="currentColor"
        />
      </svg>
      {{ $t("lbl_previous") }}
    </b-button>

    <b-button
      v-if="!disabledNext"
      :class="disabledBack ? 'ml-auto' : 'm-0'"
      variant="primary"
      @click="$emit('next')"
    >
      {{ $t("lbl_next") }}
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.4599 8.29002C8.2716 8.09641 8.01409 7.98554 7.74404 7.98179C7.47399 7.97804 7.21351 8.08172 7.0199 8.27002C6.82629 8.45833 6.71542 8.71583 6.71167 8.98588C6.70792 9.25593 6.8116 9.51641 6.9999 9.71002L9.3399 12L6.9999 14.29C6.90617 14.383 6.83178 14.4936 6.78101 14.6154C6.73024 14.7373 6.7041 14.868 6.7041 15C6.7041 15.132 6.73024 15.2627 6.78101 15.3846C6.83178 15.5065 6.90617 15.6171 6.9999 15.71C7.09286 15.8038 7.20346 15.8781 7.32532 15.9289C7.44718 15.9797 7.57789 16.0058 7.7099 16.0058C7.84191 16.0058 7.97262 15.9797 8.09448 15.9289C8.21634 15.8781 8.32694 15.8038 8.4199 15.71L11.4199 12.71C11.5136 12.6171 11.588 12.5065 11.6388 12.3846C11.6896 12.2627 11.7157 12.132 11.7157 12C11.7157 11.868 11.6896 11.7373 11.6388 11.6154C11.588 11.4936 11.5136 11.383 11.4199 11.29L8.4599 8.29002ZM16.9599 11.29L13.9599 8.29002C13.7716 8.10172 13.5162 7.99593 13.2499 7.99593C12.9836 7.99593 12.7282 8.10172 12.5399 8.29002C12.3516 8.47833 12.2458 8.73372 12.2458 9.00002C12.2458 9.26632 12.3516 9.52172 12.5399 9.71002L14.8399 12L12.5399 14.29C12.4462 14.383 12.3718 14.4936 12.321 14.6154C12.2702 14.7373 12.2441 14.868 12.2441 15C12.2441 15.132 12.2702 15.2627 12.321 15.3846C12.3718 15.5065 12.4462 15.6171 12.5399 15.71C12.6329 15.8038 12.7435 15.8781 12.8653 15.9289C12.9872 15.9797 13.1179 16.0058 13.2499 16.0058C13.3819 16.0058 13.5126 15.9797 13.6345 15.9289C13.7563 15.8781 13.8669 15.8038 13.9599 15.71L16.9599 12.71C17.0563 12.6197 17.1338 12.5112 17.1881 12.3908C17.2423 12.2704 17.2721 12.1404 17.2759 12.0084C17.2796 11.8763 17.2571 11.7449 17.2097 11.6216C17.1624 11.4983 17.0911 11.3856 16.9999 11.29H16.9599Z"
          fill="white"
        />
      </svg>
    </b-button>
  </div>
</template>

<script>
export default {
  name: "StepNavigator",
  props: {
    disabledNext: {
      type: Boolean,
      default: false
    },
    disabledBack: {
      type: Boolean,
      default: false
    },
  },
  methods: {}
};
</script>

<style>
</style>