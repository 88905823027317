import { render, staticRenderFns } from "./SelectOrderForm.vue?vue&type=template&id=21cc4b51&scoped=true"
import script from "./SelectOrderForm.vue?vue&type=script&lang=js"
export * from "./SelectOrderForm.vue?vue&type=script&lang=js"
import style0 from "./SelectOrderForm.vue?vue&type=style&index=0&id=21cc4b51&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21cc4b51",
  null
  
)

export default component.exports