<template>
  <div class="flex flex-col h-100">
    <div v-if="loading" class="text-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <template v-else>
      <SubmitFormItem
        v-for="(item, index) in orderItems"
        :key="item.id"
        :orderItem="item"
        :orderDetail="orderDetail"
        :isCancelOrder="isCancelOrder"
        :apiPayload="apiPayload[index]"
        @upload="
          ({ url_list, form_type }) =>
            handleUploadFile(url_list, index, form_type)
        "
        @create="(eform) => handleCreateEform(item, index, eform)"
      />
      <StepNavigator disabledBack @next="nextStep()" />
    </template>

    <b-modal
      :size="eform.form_type !== 1 ? 'xl' : 'lg'"
      :centered="eform.form_type === 1"
      scrollable
      hide-header
      :ok-title="$t('lbl_create')"
      :cancel-title="$t('lbl_cancel')"
      v-model="showModalConsentForm"
      @ok="handleTriggerEform"
      @hide="closeModalEform"
    >
      <CreateForm
        :formMetaData="eform"
        :list_section="eform_meta_label"
        :createConsentFormCount="createConsentFormCount"
        :productData="selectedProduct.data"
        :formCount="consentFormCount"
        :formData="selectedProduct.formData"
        @onCreate="handleSubmitEform"
        @onChangeFormData="handleRefreshFormData"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  ORDER_FORM_PROCESSOR_STATUS,
  PRODUCT_TYPES,
  RESULT_ORDER_ITEM_STATUS,
  ORDER_FORM_DRAFT
} from '@/utils/constant'
import CreateForm from '@/views/PrintOrderForm/CreateForm.vue'
import OrderForm from './OrderForm.vue'
import StepNavigator from './StepNavigator.vue'
import SubmitFormItem from './SubmitFormItem.vue'

export default {
  name: 'SubmitForm',
  components: {
    OrderForm,
    CreateForm,
    StepNavigator,
    SubmitFormItem
  },
  data() {
    return {
      orderItems: [],
      consentFormUrls: [],
      requisitionFormUrls: [],
      orderFormUrls: [],
      otherFormUrls: [],
      selectedIndex: 0,
      selectedProduct: {},
      showModalConsentForm: false,
      showModalRequisitionForm: false,
      consentFormCount: 0,
      createConsentFormCount: 0,
      requisitionFormCount: 0,
      createRequisitionFormCount: 0,
      loading: false,
      eform_meta_label: [],
      eform: {}
    }
  },
  props: {
    orderDetail: {
      type: Object,
      default: () => {}
    },
    apiPayload: Array,
    isCancelOrder: Boolean
  },
  computed: {
    isDisableNextBtn() {
      if (this.consentFormUrls.length || this.requisitionFormUrls.length) {
        let arr = []
        if (this.consentFormUrls.length) arr = [...this.consentFormUrls]
        if (this.requisitionFormUrls.length) arr = [...this.requisitionFormUrls]
        return arr.map(url => !!url).some(bool => !bool)
      } else return true
    }
  },
  watch: {
    orderDetail: {
      deep: true,
      async handler() {
        this.handleMapData(this.orderItems)
      }
    }
  },
  async mounted() {
    this.orderItems = await this.makingOrderItems(this.orderDetail)
    this.orderItems.forEach(order => {
      this.$set(order, 'url_concent_form', null)
      this.$set(order, 'url_requisition_form', null)
    })
    this.consentFormUrls = new Array(this.orderItems.length)
    this.requisitionFormUrls = new Array(this.orderItems.length)
    this.otherFormUrls = new Array(this.orderItems.length)
    this.orderFormUrls = new Array(this.orderItems.length)
    this.handleMapData(this.orderItems)
  },
  methods: {
    async handleCreateEform(productInfo, index, eform) {
      this.selectedIndex = index
      this.selectedProduct = { ...productInfo }
      this.eform_meta_label = JSON.parse(eform.meta_label)
      this.eform = eform
      this.$store.commit('orders/updateTemporaryPayload', [...this.apiPayload])
      this.showModalConsentForm = true
    },
    closeModalEform() {
      this.consentFormCount++
    },
    handleTriggerEform() {
      this.createConsentFormCount++
    },
    async handleSubmitEform(formData) {
      if (!this.selectedProduct?.data?.id) return
      if (this.createConsentFormCount) {
        const data = {
          ...formData,
          orderId: this.selectedProduct?.data?.id
        }
        this.$store.commit('orders/updateConsentForm', data)
        this.handleRefreshFormData(data)
        this.$router.replace({
          name: 'print-consent-form',
          params: {
            id: this.selectedProduct?.data?.id,
            orderItem: this.selectedProduct.data,
            form: this.eform,
            html: this.eform.html_content
          }
        })
      }
    },
    async handleRefreshFormData(newFormData) {
      await this.handleChangeFormData(newFormData)
      const formDataItem = await this.handleGetFormData(this.selectedProduct)
      this.$set(this.selectedProduct, 'formData', formDataItem)
      this.$set(this.orderItems, this.selectedIndex, this.selectedProduct)
    },
    async handleChangeFormData(formData) {
      const productId = Number(this.selectedProduct.data.id)
      const params = {
        form_type: ORDER_FORM_DRAFT.REQUISITION_FORM,
        edited_at: Date.now(),
        order_item_id: productId,
        content: JSON.stringify(formData)
      }

      try {
        await this.$rf.getRequest('CommonRequest').postDraftOrderForm(params)
      } catch (error) {
        console.log(error)
      } finally {
        this.showModalRequisitionForm = false
      }
    },
    async handleGetFormData(orderItem) {
      if (!orderItem?.data?.id) return
      try {
        const params = {
          order_item_id: orderItem.data.id,
          order_form_type: ORDER_FORM_DRAFT.REQUISITION_FORM
        }

        const response = await this.$rf
          .getRequest('CommonRequest')
          .getDraftOrderForm(params)

        const responseData = response.data?.data
        if (!responseData?.content) return
        return JSON.parse(responseData.content)
      } catch (error) {
        console.log(error)
      }
    },
    async makingOrderItems(data) {
      const newData = data?.order_items?.filter(
        item => item?.data?.type === PRODUCT_TYPES.SERVICE
      )
      const dataRaw = await Promise.all(
        newData?.map(async item => {
          const formDataItem = await this.handleGetFormData(item)
          return {
            ...item,
            formData: formDataItem
          }
        })
      )
      return dataRaw
    },
    async handleUploadFile(url_list, index, form_type) {
      let form_key
      let payload_form_key
      switch (form_type) {
        case 1:
          form_key = 'consentFormUrls'
          payload_form_key = 'url_concent_form'
          break
        case 2:
          form_key = 'requisitionFormUrls'
          payload_form_key = 'url_requisition_form'
          break
        case 100:
          form_key = 'otherFormUrls'
          payload_form_key = 'url_other_form'
          break
        default:
          form_key = 'orderFormUrls'
          payload_form_key = 'url_order_form'
          break
      }
      this[form_key][index] = [...url_list]
      this.$emit('addForms', url_list, index, payload_form_key)
    },
    async nextStep() {
      this.$emit('submitForms', {
        consentFormUrls: this.consentFormUrls,
        requisitionFormUrls: this.requisitionFormUrls,
        orderFormUrls: this.orderFormUrls,
        otherFormUrls:
          this.otherFormUrls ||
          this.otherFormUrls.map(items => {
            return items.map(file => file.url)
          })
      })
    },
    getFileName(url) {
      if (!url) return ''
      const fileName =
        typeof url === 'object'
          ? url.url.substring(url.url.lastIndexOf('/') + 1)
          : url.substring(url.lastIndexOf('/') + 1)
      return fileName
    },
    handleMapData(orderItemsData) {
      orderItemsData.forEach((item, index) => {
        const dataSelected = this.apiPayload?.find(
          payloadItem => payloadItem?.order_item_id === item?.data?.id
        )
        const orderProcessor = item?.data?.order_processor || {}
        if (orderProcessor) {
          this.consentFormUrls[index] =
            dataSelected?.url_concent_form || orderProcessor.concent_form_url
          this.requisitionFormUrls[index] = dataSelected?.url_requisition_form
            .length
            ? dataSelected?.url_requisition_form
            : orderProcessor?.requisition_form
            ? orderProcessor?.requisition_form.filter(el => el.type === 1)
            : []
          this.orderFormUrls[index] =
            dataSelected?.url_order_form || orderProcessor.order_form_url
          this.otherFormUrls[index] = dataSelected?.url_other_form.length
            ? dataSelected?.url_other_form
            : orderProcessor?.requisition_form
            ? orderProcessor?.requisition_form.filter(el => el.type === 2)
            : []
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.product-name {
  font-weight: 600;
}

.description {
  font-size: 12px;
  margin-bottom: 5px;

  a {
    text-decoration: underline;
  }
}

.save-btn {
  width: 50%;
  // transform: translate(50%, 100%);
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
}

.upload-file {
  display: flex;
  align-items: center;
  gap: 5px;
  background: #ccc;
  height: 30px;
  display: inline-flex;
  line-height: 30px;
  border-radius: 5px;
  padding: 0px 10px;
  margin-bottom: 10px;

  svg {
    width: 20px;
  }
}

.cs-box-item {
  border: 1px solid #20409b;
  padding: 12px;
  border-radius: 4px;
}

.cs-dropdown ::v-deep {
  button {
    background-color: transparent !important;
    padding: 0 !important;
    border: none !important;

    &:hover {
      background-color: transparent !important;
      padding: 0 !important;
      border: none !important;
      box-shadow: none !important;
    }

    &::after {
      display: none !important;
    }
  }
}
</style>
