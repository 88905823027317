<template>
  <div class="wrapper d-flex">
    <div v-if="!!receiverInfo">
      <p class="title">{{ $t("lbl_result_recipient") }}</p>
      <p>{{ receiverInfo.name }}</p>
      <p>{{ receiverInfo.phone }}</p>
      <p>{{ receiverInfo.email }}</p>
      <p>
        {{
        (receiverInfo.address1 ? `${receiverInfo.address1} - ` : "") +
        receiverInfo.address2
        }}
      </p>
      <div class v-if="orderPersonMetadata.medicalHistory">
        <p class="sub-title">{{ $t("lbl_medical_history") }}:</p>
        <p>{{ orderPersonMetadata.medicalHistory }}</p>
      </div>
      <div
        class
        v-if="
          orderPersonMetadata.attachments &&
          orderPersonMetadata.attachments.length
        "
      >
        <p class="sub-title">{{ $t("lbl_attachments") }}:</p>
        <div>
          <a
            :href="item"
            target="_blank"
            class="d-flex align-items-center mb-1"
            v-for="(item, index) in orderPersonMetadata.attachments"
            :key="index"
          >
            <img src="/icons/icon-paper.svg" alt />
            {{ getAttachmentTitle(item) }}
          </a>
        </div>
      </div>
    </div>
    <div v-if="!!orderPersonInfo">
      <p class="title">{{ $t("lbl_orderer") }}</p>
      <p>{{ orderPersonInfo.name }}</p>
      <p>{{ orderPersonInfo.phone }}</p>
      <p>{{ orderPersonInfo.email }}</p>
      <p>
        {{
        (orderPersonInfo.address1 ? `${orderPersonInfo.address1} - ` : "") +
        orderPersonInfo.address2
        }}
      </p>
    </div>
    <div v-if="!!productOrder">
      <p class="title">{{ $t("lbl_notes") }}</p>
      <p>{{ productOrder && productOrder.notes }}</p>
    </div>
  </div>
</template>

<script>
import appUtils from '@/utils/appUtils'

export default {
  props: {
    orderContact: {
      type: Array,
      default: () => {}
    },
    productOrder: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    orderPersonInfo() {
      return this.orderContact?.find(person => person.type == 1)
    },
    orderPersonMetadata() {
      try {
        const metadata = this.receiverInfo?.meta_data
          ? JSON.parse(this.receiverInfo?.meta_data)
          : {}
        return metadata
      } catch (error) {
        console.log(error)
        return {}
      }
    },
    receiverInfo() {
      return this.orderContact?.find(person => person.type == 2)
    }
  },
  methods: {
    getAttachmentTitle(string) {
      if (typeof string === 'string' && string.includes('https')) {
        const fileName = string.substring(string.lastIndexOf('/') + 1)
        return appUtils.truncateHash(fileName, 15)
      } else {
        return appUtils.truncateHash(string?.name, 15)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  flex-direction: column;
  gap: 15px;
}

.title {
  font-size: 18px;
  font-weight: 600;
}

.sub-title {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
}
</style>
