<template>
  <div>
    <span class="title-form">{{
      `${$t("lbl_create")} ${formMetaData.form_name}` ||
      $t("lbl_create_consent_form")
    }}</span>
    <div id="printOrderForm" class="mt-3">
      <div v-for="section in list_section" :key="section.section_title">
        <span class="cs-title">{{ $t(section.section_title) }}</span>
        <template v-if="!section.row">
          <template v-if="!section.layout">
            <div
              v-for="input in section.input_list"
              :key="input.key"
              class="cs-flex flex-wrap"
            >
              <span v-if="input.label" class="cs-label">{{
                $t(input.label)
              }}</span>
              <component
                :is="input.component || 'b-form-input'"
                class="cs-content"
                v-model="form[input.key]"
                :options="input.options || optionsEditor"
                :name="input.name"
                :locale="$i18n.locale"
                v-bind="$getCalendarLabel($i18n.locale)"
                :style="input.style"
              />
              <span
                v-if="input.note"
                class="cs-flex"
                style="flex-basis: 100%"
                >{{ $t(input.note) }}</span
              >
            </div>
          </template>
          <template v-else>
            <div
              v-for="input in section.input_list"
              :key="input.key"
              style="padding: 6px"
              :style="input.style"
            >
              <p
                v-if="input.label"
                class="items-center"
                style="margin-bottom: 0.5rem"
                :style="input.labelStyle"
              >
                {{ $t(input.label) }}
              </p>
              <component
                :is="input.component || 'b-form-input'"
                class="cs-content"
                v-model="form[input.key]"
                :options="input.options || optionsEditor"
                :name="input.name"
                :locale="$i18n.locale"
                v-bind="$getCalendarLabel($i18n.locale)"
                :style="input.inputStyle"
              />
            </div>
          </template>
        </template>
        <template v-else>
          <b-row class="mt-1 mb-1 mx-0">
            <b-col
              v-for="input in section.input_list"
              :key="input.key"
              class="text-center"
              :cols="input.colSpan"
            >
              <strong>{{ $t(input.label) }}</strong>
            </b-col>
          </b-row>
          <b-row
            v-for="(row, index) in form[section.rowListKey]"
            :key="index"
            class="mt-1 px-1"
          >
            <b-col
              v-for="input in section.input_list"
              :key="input.key"
              :class="input.component != 'b-form-checkbox' && 'text-center'"
              :cols="input.colSpan"
            >
              <component
                v-if="input.component != 'b-form-checkbox'"
                :is="input.component || 'b-form-input'"
                class="cs-content"
                v-model="row[input.key]"
                :options="input.options || optionsEditor"
                :name="input.name"
                :locale="$i18n.locale"
                v-bind="$getCalendarLabel($i18n.locale)"
              />
              <template v-else>
                <div class="cs-flex cs-checkbox py-0">
                  <input
                    v-model="row[input.key]"
                    type="checkbox"
                    class="cs-none"
                  />
                  <label class="cs-content">
                    <strong>{{ row[input.key] }}</strong>
                  </label>
                </div>
              </template>
            </b-col>
          </b-row>
        </template>
      </div>
    </div>
  </div>
</template>
  
<script>
import { mapState } from "vuex";
import { quillEditor } from "vue-quill-editor";

const optionsEditor = {
  placeholder: "",
  theme: "snow",
};

export default {
  name: "ModalCreateForm",
  components: { quillEditor },
  props: {
    productData: Object,
    createConsentFormCount: Number,
    formCount: Number,
    list_section: Array,
    formMetaData: Object,
    formData: Object
  },
  data() {
    return {
      optionsEditor,
      // List section and form is got from API
      form: {},
    };
  },
  computed: {
    ...mapState({
      orders: (state) => state.orders,
    }),
  },
  watch: {
    createConsentFormCount() {
      this.handleCreateEform();
    },
    formCount(count) {
      if (!count) return;
      this.handleGetFormData();
    },
    orders: {
      deep: true,
      handler(data) {
        if (data?.viewDetail) {
          this.handleMapData(data?.viewDetail);
        }
      },
    },
  },
  async created() {
    await this.renderEform()
    if (this.orders?.viewDetail) this.handleMapData(this.orders.viewDetail);
  },
  methods: {
    async renderEform() {
      await this.list_section.forEach((section) => {
      section.input_list.forEach((input) => {
        this.$set(this.form, input.key, null);
        if (
          ["b-form-radio-group", "b-form-checkbox-group"].includes(
            input.component
          )
        ) {
          input.options.forEach((opt) => {
            opt.text = opt.labelMultilang
              ? this.$t(opt.labelMultilang)
              : opt.text;
          });
        }
      });
    });
    
    },
    handleCreateEform() {
      if (this.createConsentFormCount) {
        this.$emit("onCreate", this.form);
      }
    },
    handleMapData(data) {
      if (this.formData) {
        this.form = { ...this.formData };
      }
      const patientInfo =
        data?.order_contact?.find((item) => item?.type === 2) ||
        data?.order_contact?.find((item) => item?.type === 1);
      this.form.full_name = this.formData?.full_name || patientInfo?.name;
      this.form.product_name = this.productData?.product_name;
      this.form.guardian_full_name = this.productData?.guardian_full_name || "";
      this.form.paid_by = this.formData?.paid_by || 2;
      this.form.bill_address =
        this.formData?.bill_address ||
        patientInfo?.address1 ||
        patientInfo?.address2;
      this.form.bill_phone = this.formData?.bill_phone || patientInfo?.phone;
      this.form.bill_email = this.formData?.bill_email || patientInfo?.email;
      this.form.patient_name = this.formData?.patient_name || patientInfo?.name;
      this.form.acc_address = this.formData?.acc_address ||
        patientInfo?.address1 ||
        patientInfo?.address2;

      if (!this.productData) return;
      const listProduct = this.formData?.test_selection || [this.productData];
      this.form.test_selection = [...listProduct];
      this.form.sample_requirement = [...listProduct];
      this.form.family_cancer = this.formData?.family_cancer && this.formData?.family_cancer.length ? this.formData?.family_cancer : [{}, {}, {}]
      this.form.guardian_full_name = this.formData?.guardian_full_name
    },
    handleGetFormData() {
      this.$emit("onChangeFormData", this.form);
    },
  },
};
</script>
  
<style lang="scss" scoped>
.title-form {
  font-size: 24px;
  color: black;
  text-align: center;
}

.cs-container {
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;

  * {
    color: black;
    font-family: "Times New Roman", "Tinos", Times, serif !important;
  }

  #printOrderForm {
    margin: auto;
    // margin-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
  }

  h2 {
    // color: black;
    font-size: 28px;
  }
}

.container-border {
  border: 1px solid #5c5c5c;
  padding: 0;
  width: 50%;
}

.cs-title {
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 6px;
}

.cs-flex {
  display: flex;
  gap: 8px;
  padding: 6px;
  box-sizing: border-box;
  align-items: center;
}

.cs-label {
  width: 150px;
}

.cs-width-full {
  width: 100% !important;
}

.cs-font-bold {
  font-weight: bold;
}

.cs-checkbox {
  display: flex;
  align-items: center;

  input {
    width: 18px;
    height: 18px;
  }

  input[type="checkbox"] {
    accent-color: #20419b;
  }

  label {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-left: 6px;
  }
}

.cs-content {
  flex: 1;
}

.min-checkbox {
  min-width: 120px;
}
.box-content {
  display: block;
  padding: 6px;
  min-height: 120px;
}
.html2pdf__page-break {
  page-break-before: always;
}

.cs-padding {
  padding: 6px;
}

.cs-border {
  border: 1px solid #5c5c5c;
}

.content-none {
  width: 100%;
  border-bottom: 2px dashed;
}

.cs-text-sm {
  font-size: 11px;
  font-weight: 500;
}

.cs-text-md {
  font-size: 16px;
}

.cs-list {
  display: flex;
  gap: 12px;
}

.cs-ol {
  li {
    position: relative;
    padding-left: 10px;
    margin-left: 15px;
  }

  li:before {
    position: absolute;
    left: -2px;
  }
}

.cs-none {
  pointer-events: none;
}
</style>