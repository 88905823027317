<template>
  <div>
    <b-overlay :show="isLoading" rounded="sm">
      <b-card>
        <h4>Ghi chú</h4>
        <b-textarea v-model="note" placeholder="Nhập ghi chú"></b-textarea>
        <div class="flex justify-content-end">
          <b-button
            :disabled="!note || isLoading"
            variant="primary"
            class="mt-1"
            @click="saveNotes"
          >
            Lưu</b-button
          >
        </div>
        <div class="mt-2">
          <h5 class="mb-1 font-bold">Lịch sử ghi chú</h5>
          <div v-if="listNotes && listNotes.length" class="list-note w-full">
            <div
              class="flex gap-2 note-item"
              v-for="item in listNotes"
              :key="item.id"
            >
              <div class="circle">
                <feather-icon icon="Edit3Icon" size="16"></feather-icon>
              </div>
              <div>
                <div class="note mb-1">{{ item.note }}</div>
                <small>
                  bởi
                  <span class="font-bold">{{
                    item.create_by_info && item.create_by_info.name
                  }}</span>
                </small>
                <br />
                <small>
                  vào lúc
                  <span class="font-bold">{{
                    appUtils.formatDateTime(item.created_at)
                  }}</span>
                </small>
              </div>
            </div>
            <div class="flex justify-center">
              <b-button
                variant="primary"
                size="small"
                @click="getSupplierOrderNotes(true)"
                :disabled="isLoading"
                v-if="page_num < last_page"
              >
                {{ $t("Xem thêm") }}
              </b-button>
            </div>
          </div>

          <div v-else class="text-center">
            <h5>{{ $t("Chưa có ghi chú nào") }}</h5>
          </div>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import appUtils from "../../utils/appUtils";

export default {
  name: "SupplierOrderNotes",
  data() {
    return {
      note: "",
      isLoading: false,
      listNotes: [],
      appUtils,
      page_num: 1,
      last_page: 1,
    };
  },
  created() {
    this.getSupplierOrderNotes();
  },
  methods: {
    async getSupplierOrderNotes(isNext) {
      if (!this.$route.params?.id) return;

      try {
        this.isLoading = true;
        const currentPage = isNext ? ++this.page_num : 1;
        const params = {
          supplier_order_id: Number(this.$route.params.id),
          page_size: 2,
          page_num: currentPage,
          sort_by: "desc",
          sort: "created_at",
        };
        const response = await this.$rf
          .getRequest("CommonRequest")
          .getSupplierOrderNotes(params);

        if (isNext) {
          this.listNotes = [...this.listNotes, ...response.data?.data?.data];
        } else {
          this.listNotes = response.data?.data?.data || [];
        }

        this.last_page = response.data?.data?.meta?.last_page;
        this.page_num = response.data?.data?.meta?.page;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    async saveNotes() {
      if (!this.$route.params?.id) return;

      if (!this.note) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Vui lòng nhập ghi chú"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return;
      }

      try {
        this.isLoading = true;
        const params = {
          note: this.note,
          supplier_order_id: Number(this.$route.params.id),
        };

        const response = await this.$rf
          .getRequest("CommonRequest")
          .saveSupplierOrderNotes(params);
        if (response.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Lưu ghi chú thành công"),
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.note = "";
          await this.getSupplierOrderNotes();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    handleClickSaveNotes() {
      const self = this;

      self.$bvModal
        .msgBoxConfirm("Xác nhận lưu ghi chú", {
          title: "Xác nhận",
          size: "sm",
          okVariant: "primary",
          okTitle: "Xác nhận",
          cancelTitle: "Huỷ bỏ",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            self.saveNotes();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.list-note {
  .note-item {
    border-top: 1px solid #dbdbdb;
    padding: 12px 0;
    &:first-child {
      border: unset;
    }
  }
}
</style>