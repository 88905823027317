<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
    >
      <b-row>
        <b-col cols="12" :lg="8">
          <b-card no-body>
            <div class="px-1 pt-1">
              <h3
                class="d-flex justify-content-between w-100 border-bottom pb-75 mb-75"
              >
                <div>
                  <span>
                    {{
                      $role === 1
                        ? $t("lbl_test_requirement_info")
                        : $t("lbl_order_info")
                    }}
                  </span>
                  <span
                    v-if="
                      orderDetail.slip && orderDetail.slip.is_emergency === 2
                    "
                    class="ml-1"
                  >
                    <b-badge variant="danger" size="small" class="badge-glow">
                      {{ $t("Cấp cứu") }}
                    </b-badge>
                  </span>
                </div>
                <span>#{{ orderDetail.order_code }}</span>
              </h3>
            </div>
            <div v-if="orderItemsProduct.length" class="px-1 mb-2">
              <h4 v-if="orderItemsService.length">
                <u>{{ $t("lbl_products") }}</u>
              </h4>
              <div class="bg-light p-1 rounded">
                <OrderInfomation
                  :orderDetail="orderDetail"
                  :orderItems="orderItemsProduct"
                  @refresh="handleGetViewDetail"
                  @setIsLoading="setIsLoading"
                  :isCancelOrder="isCancelOrder"
                />
              </div>
            </div>
            <div v-if="orderItemsService.length" class="px-1 mb-2">
              <h4 v-if="orderItemsProduct.length">
                <u>{{ $t("SERVICE") }}</u>
              </h4>
              <div class="bg-light rounded-top">
                <OrderInfomation
                  :orderDetail="orderDetail"
                  :orderItems="orderItemsService"
                  @refresh="handleGetViewDetail"
                  @setIsLoading="setIsLoading"
                  :isCancelOrder="isCancelOrder"
                />
              </div>
              <div class="p-1 rounded-bottom" style="background-color: #ebe9f1">
                <b-row>
                  <b-col cols="12" :lg="3">
                    <div class="process-timeline">
                      <div
                        v-for="(node, index) in activities"
                        :key="node.text"
                        class="process-timeline__item d-flex cursor-pointer"
                        v-b-tooltip.left
                        :title="
                          checkProcessorReject && index === 2
                            ? $t('lbl_order_is_rejected')
                            : ''
                        "
                        @click="
                          checkProcessorReject && index === 2
                            ? () => {}
                            : changeStep(node)
                        "
                      >
                        <div
                          class="node"
                          :class="{
                            active: node.isActive,
                            done: activeSteps[index],
                            cancel: checkProcessorReject && index === 2,
                          }"
                        >
                          <div
                            v-if="index < activities.length - 1"
                            class="node-line"
                          ></div>
                        </div>
                        <div class="node-text">{{ $t(node.text) }}</div>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="12" :lg="9">
                    <div class="h-100">
                      <keep-alive>
                        <Transition name="fade" mode="out-in">
                          <component
                            v-if="orderDetail.hasOwnProperty('order_items')"
                            :is="activeComponent"
                            :orderDetail="orderDetail"
                            :apiPayload="payload"
                            :isCancelOrder="isCancelOrder"
                            :checkProcessorReject="checkProcessorReject"
                            class="order-detail-container"
                            @changeStatus="handleChangeStatus"
                            @onChangeStep="onChangeStep"
                            @submitForms="handleSubmitForms"
                            @addForms="handleAddFiles"
                            @refresh="handleGetViewDetail"
                          />
                        </Transition>
                      </keep-alive>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col cols="12" :lg="4">
          <b-card no-body>
            <div
              class="p-1 flex items-center justify-center"
              v-if="
                orderDetail &&
                orderDetail.product_order &&
                orderDetail.product_order.delivery_status !== 2
              "
            >
              <b-button variant="primary" @click="handleConfirmComplete"
                >Xác nhận hoàn thành đơn hàng</b-button
              >
            </div>
            <div v-else class="flex flex-col p-1">
              <b-badge variant="info" class="text-base"
                >Đơn hàng đã hoàn thành</b-badge
              >
              <small
                v-if="
                  orderDetail.product_order &&
                  orderDetail.product_order.user_info &&
                  orderDetail.product_order.user_info.id
                "
              >
                Xác nhận bởi
                <b>{{
                  orderDetail.product_order &&
                  orderDetail.product_order.user_info &&
                  orderDetail.product_order.user_info.name
                }}</b>
                <br />vào lúc
                <b>{{
                  appUtils.formatDateTime(
                    orderDetail.product_order &&
                      orderDetail.product_order.delivery_at
                  )
                }}</b>
              </small>
            </div>
          </b-card>
          <b-card no-body>
            <div class="tabs-wrapper">
              <b-tabs fill content-class="mt-1">
                <b-tab active>
                  <template #title>
                    <div
                      class="w-100"
                      v-b-tooltip.hover
                      :title="$t('lbl_customer_order_info')"
                    >
                      <feather-icon icon="UserIcon" size="24" class="mr-0" />
                      <span class="d-none">
                        {{ $t("lbl_customer_order_info") }}
                      </span>
                    </div>
                  </template>
                  <OrderReceiverInformation
                    :orderContact="orderDetail.order_contact"
                    :productOrder="orderDetail.product_order"
                  />
                </b-tab>
                <b-tab>
                  <template #title>
                    <div
                      class="w-100"
                      v-b-tooltip.hover
                      :title="$t('Followers')"
                    >
                      <feather-icon icon="BellIcon" size="24" class="mr-0" />
                      <span class="d-none">{{ $t("Followers") }}</span>
                    </div>
                  </template>
                  <OrderFollowerInformation
                    v-if="orderDetail.hasOwnProperty('followers')"
                    :followers="orderDetail.followers"
                  />
                </b-tab>
                <!-- <b-tab>
                <template #title>
                  <div class="w-100" v-b-tooltip.hover :title="$t('lbl_payment_info')">
                    <feather-icon icon="CreditCardIcon" size="24" class="mr-0" />
                    <span class="d-none">{{ $t("lbl_payment_info") }}</span>
                  </div>
                </template>
                <OrderPaymentInformation />
                </b-tab>-->
              </b-tabs>
            </div>
          </b-card>
          <b-card no-body>
            <h3 class="block p-1 mb-0">
              <span>
                <!-- {{
              $t($role===1? "lbl_total_price" : "lbl_total_order_price")
                }}:-->
                Thông tin thanh toán
              </span>
            </h3>
            <!-- Thông tin tổng giá trị đơn hàng -->
            <div v-if="slip && slip.number_code" class="px-1 pb-1">
              <span>Mã phiếu thu</span>
              <div class="text-base font-bold mb-0 h5">
                #{{ slip && slip.number_code }}
              </div>
            </div>

            <div class="px-1 pb-1">
              <PaymentMethods
                :data="(orderDetail && orderDetail.payment) || {}"
              />
              <!-- SELECT -->
              <div class="d-flex flex-column">
                <!-- <span>{{ $t("lbl_status") }}</span> -->
                <div class="d-flex flex-1 justify-content-end">
                  <div
                    v-click-outside="handleCloseDropDown"
                    class="cs-relative"
                    style="width: 100%"
                  >
                    <div
                      @click="handleToggleDropdown"
                      class="d-flex align-items-center justify-content-center"
                      :class="{
                        'not-payment cursor-pointer justify-content-between':
                          paymentInfo.value === PAYMENT_TYPE[0].value,
                        payment: paymentInfo.value === PAYMENT_TYPE[1].value,
                        status: true,
                      }"
                    >
                      <div>{{ paymentInfo.label }}</div>

                      <feather-icon
                        v-if="
                          paymentInfo.value === PAYMENT_TYPE[0].value &&
                          orderDetail.slip &&
                          orderDetail.slip.owner_type !==
                            TRANSACTION_SLIP_TYPE.Ws
                        "
                        icon="ChevronDownIcon"
                        size="20"
                        class="text-white ml-1"
                      />
                    </div>
                    <div
                      style="font-size: 14px"
                      v-if="
                        paymentInfo.value === PAYMENT_TYPE[1].value &&
                        getPaidLabel()
                      "
                    >
                      {{ `Cập nhật bởi` }}
                      <strong>{{ getPaidLabel() }}</strong>
                    </div>
                    <div v-if="isOpenDropdown" class="menu-select">
                      <div
                        @click="handleSelectPaymentType(item)"
                        class="cs-menu-item"
                        :class="{
                          'cs-item-active': item.value === paymentInfo.value,
                        }"
                        v-for="item in PAYMENT_TYPE"
                        :key="item.value"
                      >
                        <span>{{ item.label }}</span>
                        <feather-icon
                          v-if="item.value === paymentInfo.value"
                          icon="CheckIcon"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="px-1 mb-1">
              <div
                :key="index"
                v-for="(item, index) in orderDetail.order_items"
                class="flex flex-col md:flex-row gap-1 mb-1"
              >
                <div
                  @click="handleRedirectOrder(item)"
                  class="text-blue-900 font-bold"
                  :class="{
                    'cursor-pointer cs-text':
                      item.data && item.data.type === PRODUCT_TYPES.PRODUCT,
                  }"
                >
                  {{ item.data.product_name }}
                </div>
                <div class="flex justify-between items-center">
                  <div>{{ $t("Thành tiền") }}</div>
                  <div class="text-right">
                    {{ item.data.qty }}
                    x
                    {{ appUtils.numberFormat(item.data.price, "VNĐ") }}
                  </div>
                </div>
                <div
                  v-if="item.data.voucher_info && item.data.voucher_info.code"
                  class="flex justify-between items-center"
                >
                  <div>{{ $t("Giảm giá") }}</div>
                  <div>-{{ getDiscountPrice(item.data) }}</div>
                </div>
              </div>
            </div>

            <div class="px-1 pb-1 flex justify-between items-center gap-2">
              <h4>Tổng tiền</h4>
              <div class="text-primary cs-bg p-1 h3 mb-0">
                {{ totalAmount }}
              </div>
            </div>
          </b-card>
          <SupplierOrderNotes />
          <CommentSection
            v-if="orderDetail.order_items && orderDetail.order_items[0]"
            :order="orderDetail"
          />
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import SupplierOrderNotes from "@/components/SupplierOrderNotes/index.vue";
import i18n from "@/libs/i18n";
import store from "@/store";
import appUtils from "@/utils/appUtils";
import {
  ORDERS_STATUS,
  ORDER_FORM_PROCESSOR_STATUS,
  PAYMENT_TYPE,
  PRODUCT_TYPES,
  RESULT_ORDER_ITEM_STATUS,
  SERVICE_GROUP_TYPE,
  TRANSACTION_SLIP_TYPE,
} from "@/utils/constant";
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import useOrdersList from "../useOrdersList";
import AssignProcessor from "./Components/AssignProcessor.vue";
import CommentSection from "./Components/CommentSection.vue";
import OrderFollowerInformation from "./Components/OrderFollowerInformation.vue";
import OrderInfomation from "./Components/OrderInfomation.vue";
import OrderPaymentInformation from "./Components/OrderPaymentInformation.vue";
import OrderReceiverInformation from "./Components/OrderReceiverInformation.vue";
import PaymentMethods from "./Components/PaymentMethods.vue";
import Result from "./Components/Result.vue";
import SubmitForm from "./Components/SubmitForm.vue";

export default {
  components: {
    CommentSection,
    OrderInfomation,
    SubmitForm,
    OrderReceiverInformation,
    OrderPaymentInformation,
    OrderFollowerInformation,
    Result,
    AssignProcessor,
    PaymentMethods,
    SupplierOrderNotes,
  },
  setup() {
    const { viewDetailOrders } = useOrdersList();
    return {
      viewDetailOrders,
    };
  },
  data() {
    const initialTab = [
      {
        text: "lbl_test_register_profile",
        isActive: true,
        component: SubmitForm,
      },
      {
        text: "lbl_assign_processor_node",
        isActive: false,
        component: AssignProcessor,
      },
      {
        text: "lbl_return_the_results_to_customer",
        isActive: false,
        component: Result,
      },
    ];
    return {
      appUtils,
      isLoading: true,
      orderDetail: {},
      initialTab,
      activities: [...initialTab],
      payload: [],
      currentActive: initialTab[0],
      orderItemsProduct: [],
      orderItemsService: [],
      payment_status: 1,
      PAYMENT_TYPE,
      isOpenDropdown: false,
      paymentInfo: {},
      TRANSACTION_SLIP_TYPE,
      PRODUCT_TYPES,
      note: "",
      isLoadingNote: false,
    };
  },
  async created() {
    await this.handleGetViewDetail({ id: this.orderId });
    this.isLoading = false;
    this.payment_status = this.orderDetail?.payment?.status === 2 ? 2 : 1;
  },
  mounted() {
    if (Number(this.$route.query.step) === 1) {
      const stepInfo = this.initialTab[1];
      this.changeStep(stepInfo);
      this.$router.replace({ query: null });
    }
  },
  computed: {
    slip() {
      return this.orderDetail?.slip;
    },
    payment() {
      return this.orderDetail?.payment;
    },
    isCancelOrder() {
      return this.orderDetail?.status === ORDERS_STATUS.CANCELED;
    },
    orderId() {
      return this.$route.params.id;
    },
    activeComponent() {
      return this.activities.find((node) => node.isActive)?.component;
    },
    ...mapState({
      payloadOrdersStore: (state) => state.orders.payloadOrders,
      is_test_provider: (state) => state.authenticate.is_test_provider,
      temporaryPayload: (state) => state.orders.temporaryPayload,
    }),
    activeSteps() {
      const self = this;
      const activeStepsRaw = self.payload?.map((p, index) => {
        const isConsentAndRequisitionForm =
          !!p.url_concent_form && !!p.url_requisition_form;
        // const isOrderForm = !!p.url_order_form;
        const isAssignProcessor = !!p.processor_id;
        const isResult =
          self.orderDetail?.order_items[index]?.data?.document_link?.[0]
            ?.status === RESULT_ORDER_ITEM_STATUS.APPROVE;
        return {
          isConsentAndRequisitionForm,
          // isOrderForm,
          isAssignProcessor,
          isResult,
        };
      });
      const isConsentAndRequisitionForm = [false].some((el) =>
        activeStepsRaw
          ?.map((item) => item?.isConsentAndRequisitionForm)
          .includes(el)
      );
      // const isOrderForm = [false].some((el) =>
      //   activeStepsRaw?.map((item) => item?.isOrderForm).includes(el)
      // );
      const isAssignProcessor = [false].some((el) =>
        activeStepsRaw?.map((item) => item?.isAssignProcessor).includes(el)
      );
      const isResult = [false].some((el) =>
        activeStepsRaw?.map((item) => item?.isResult).includes(el)
      );

      return [
        !isConsentAndRequisitionForm,
        // !isOrderForm,
        !isAssignProcessor,
        !isResult,
      ];
    },
    totalAmount() {
      const total = this.orderDetail?.order_items?.reduce(
        (total, item) => total + Number(item?.data?.total_amount),
        0
      );
      if (!total && total !== 0) return "";
      return appUtils.numberFormat(total, "VNĐ");
    },
    checkProcessorReject() {
      const isReject = this.orderDetail?.order_items?.some(
        (item) =>
          item?.data?.order_processor?.status ===
          ORDER_FORM_PROCESSOR_STATUS.CANCEL
      );
      return isReject;
    },
  },
  watch: {
    orderDetail: {
      handler(data) {
        if (Object.keys(data)?.length) {
          this.makingOrderItems(data);
        }
      },
      deep: true,
    },

    $route() {
      if (Number(this.$route.query.step) === 1) {
        const stepInfo = this.initialTab[1];
        this.changeStep(stepInfo);
        this.$router.replace({ query: null });
      }

      this.handleGetViewDetail({ id: this.orderId });
    },
  },
  methods: {
    ...mapActions("orders", ["fetchOrdersById"]),
    makingOrderItems(data) {
      // this.orderItemsProduct = data?.order_items?.filter(
      //   (item) => item?.data?.type === PRODUCT_TYPES.PRODUCT
      // );
      this.orderItemsService = data?.order_items
        ?.filter((item) => item?.data?.type === PRODUCT_TYPES.SERVICE)
        ?.map((item) => ({
          ...item,
          isSkipProcessor:
            item.service_catalog?.service_category
              ?.service_group_category_id === SERVICE_GROUP_TYPE.patientVisit,
        }));
    },
    changeStep(step) {
      this.$route.params.url = null;
      this.activities.find((node) => node.isActive).isActive = false;
      step.isActive = true;
      this.currentActive = { ...step };
    },
    nextStep() {
      let indexOfCurrentStep = this.activities
        .map((step) => step.isActive)
        .indexOf(true);
      let nextStep = this.activities[indexOfCurrentStep + 1];
      this.$store.commit("orders/updatePayloadOrders", this.payload);
      this.changeStep(nextStep);
    },
    handleChangeStatus(payload) {
      let item = this.orderDetail.order_items.find(
        (item) => item.data.id === payload.order_item_id
      );
      if (item) {
        item.data.status = payload.status.order_number;
        item.stepSelected = payload.status;
      }
    },
    handleSubmitForms({
      requisitionFormUrls,
      consentFormUrls,
      orderFormUrls,
      otherFormUrls,
    }) {
      requisitionFormUrls.forEach((urls, index) => {
        this.payload[index].url_requisition_form = urls;
      });
      consentFormUrls.forEach((urls, index) => {
        this.payload[index].url_concent_form = urls;
      });
      orderFormUrls.forEach((urls, index) => {
        this.payload[index].url_order_form = urls;
      });
      otherFormUrls.forEach((urls, index) => {
        this.payload[index].url_other_form = urls;
      });
      this.nextStep();
    },
    handleAddFiles(formUrls, index, form_key) {
      this.payload[index][form_key] = [...formUrls];
    },
    isJSON(params) {
      if (params) {
        try {
          JSON.parse(params);
        } catch (error) {
          return false;
        }
        return typeof JSON.parse(params) === "object";
      } else return false;
    },
    async handleGetViewDetail(orderInfo) {
      const data = await this.viewDetailOrders(orderInfo);
      this.orderDetail = { ...data };
      this.paymentInfo =
        PAYMENT_TYPE.find(
          (item) => item.value === this.orderDetail?.payment?.status
        ) || {};

      const orderItemsService = this.orderDetail?.order_items?.filter(
        (item) =>
          item?.data?.type === PRODUCT_TYPES.SERVICE &&
          item?.service_catalog?.service_category?.service_group_category_id !==
            SERVICE_GROUP_TYPE.patientVisit
      );
      if (!orderItemsService) return;
      if (this.temporaryPayload && this.temporaryPayload.length) {
        this.payload = this.temporaryPayload;
        this.$store.commit("orders/updateTemporaryPayload", []);
      } else {
        const newPayload = orderItemsService?.map((el) => {
          const payloadInfo = this.payloadOrdersStore?.find(
            (payloadItem) => payloadItem?.order_item_id === el?.data?.id
          );
          return {
            order_code: this.orderDetail.order_code,
            order_id: this.orderDetail.order_id,
            order_item_id: el.data.id,
            processor_id: el.data?.order_processor?.processor_id,
            url_concent_form:
              payloadInfo?.url_concent_form ||
              (this.isJSON(el.data?.order_processor?.concent_form_url)
                ? JSON.parse(el.data?.order_processor?.concent_form_url)
                : []),
            url_requisition_form:
              payloadInfo?.url_requisition_form ||
              el.data?.order_processor?.requisition_form
                ?.filter((item) => item.type === 1)
                .map((el) => {
                  return {
                    url: el.url,
                    canProcessorView: el.canProcessorView,
                  };
                }) ||
              [],
            url_order_form:
              payloadInfo?.url_order_form ||
              (this.isJSON(el.data?.order_processor?.order_form_url)
                ? JSON.parse(el.data?.order_processor?.order_form_url)
                : []),
            url_other_form:
              payloadInfo?.url_other_form ||
              el.data?.order_processor?.requisition_form
                ?.filter((item) => item.type === 2)
                .map((el) => {
                  return {
                    url: el.url,
                    canProcessorView: el.canProcessorView,
                  };
                }) ||
              [],
            id: el.data?.order_processor?.id,
            status: el.data?.order_processor?.status,
          };
        });
        this.payload = newPayload;
      }
      this.isLoading = false;
    },
    setIsLoading(value) {
      this.isLoading = value;
    },
    onChangeStep(index) {
      let step = this.activities[index];
      this.changeStep(step);
    },
    async handlePaymentReceipt() {
      if (!this.orderDetail?.slip?.service_transaction_slip_code) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Không tìm thấy thông tin phiếu thu"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return;
      }
      try {
        this.isLoading = true;
        await store.dispatch("orders/paymentTransactionSlipOff", {
          tx_code: this.orderDetail?.slip?.service_transaction_slip_code,
        });
        this.isOpenDropdown = false;

        await this.handleGetViewDetail({ id: this.orderId });
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Đổi trạng thái thanh toán thành công"),
            icon: "CheckIcon",
            variant: "success",
          },
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;

        console.log(error);
      } finally {
        //
      }
    },
    async handleSelectPaymentType(option) {
      if (option.value === PAYMENT_TYPE[1].value) {
        this.$bvModal
          .msgBoxConfirm(
            "Bạn có chắc chắn muốn cập nhật trạng thái sang đã thanh toán không?",
            {
              title: "Xác nhận trạng thái",
              size: "sm",
              okVariant: "primary",
              okTitle: "Xác nhận",
              cancelTitle: "Huỷ",
              cancelVariant: "outline-secondary",
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              // this.paymentInfo = option;
              this.handlePaymentReceipt();
              return;
            } else {
              return;
            }
          });
      }
    },
    handleCloseDropDown() {
      this.isOpenDropdown = false;
    },
    handleToggleDropdown() {
      if (
        this.paymentInfo.value === PAYMENT_TYPE[1].value ||
        this.orderDetail?.slip?.owner_type === TRANSACTION_SLIP_TYPE.Ws
      )
        return;
      this.isOpenDropdown = !this.isOpenDropdown;
    },
    handleRedirectOrder(item) {
      if (item.data.type === PRODUCT_TYPES.PRODUCT) {
        this.$router.push({
          name: "orders-market-place",
          query: {
            orderId: this.orderDetail?.id,
          },
        });
      }
    },
    async handleConfirmComplete() {
      const nodeEl = this.$createElement;
      const messageVNode = nodeEl("div", {
        domProps: {
          innerHTML: this.$t("Bạn chăc chắn muốn hoàn thành đơn hàng không?"),
        },
      });

      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: this.$t("lbl_please_confirm"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("lbl_yes"),
          cancelTitle: this.$t("lbl_cancel"),
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (success) => {
          if (success) {
            try {
              this.isLoading = true;
              await Vue.prototype.$rf
                .getRequest("CommonRequest")
                .completeOrder(this.orderDetail.product_order?.ID)
                .then(async () => {
                  await this.handleGetViewDetail({ id: this.orderId });
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: i18n.t("Xác nhận hoàn thành đơn hàng thành công"),
                      icon: "CheckCircleIcon",
                      variant: "success",
                    },
                  });
                  this.isLoading = false;
                });
            } catch (error) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: i18n.t("Đơn hàng chưa trả đủ KQ, vui lòng thử lại"),
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
              this.isLoading = false;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDiscountPrice(data) {
      return appUtils.numberFormat(
        data.voucher_info?.discount_amount || 0,
        "VNĐ"
      );
    },
    getPaidLabel() {
      const name = this.orderDetail?.payment?.cashier_info?.name || "";
      const time = this.orderDetail?.payment?.transaction_time
        ? window
            .moment(this.orderDetail?.payment?.transaction_time)
            .subtract(7, "hours")
            .format("HH:mm:ss DD/MM/YYYY")
        : "";
      return name && time ? `${name} - ${time}` : "";
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0px;
}

.title {
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 25px;

  span {
    margin-left: 10px;
    font-weight: 400;
    font-size: 15px;
  }
}

.process-timeline {
  &__item {
    align-items: center;
    gap: 20px;
    height: 80px;

    .node {
      width: 28px;
      min-width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: #6a7580;
      position: relative;
      transition: all 0.3s ease;

      &-text {
        transition: all 0.3s ease;
      }

      &-line {
        position: absolute;
        width: 4px;
        height: 70px;
        background-color: #ced4da;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      }

      &.active {
        background-color: #476dd6;

        & + .node-text {
          color: #476dd6;
        }
      }

      &.done {
        background-color: #28c76f !important;

        &::before {
          content: "✓";
          color: white;
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &.cancel {
        background-color: rgb(243, 22, 22) !important;

        &::before {
          content: "x";
          color: white;
          display: block;
          position: absolute;
          top: 48%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    &:hover {
      // zoom: 1.1;
      .node {
        background-color: #476dd6;

        &-text {
          color: #476dd6;
        }
      }
    }
  }
}

.order-detail-wrapper {
  padding: 15px;
  background-color: white;
  border-radius: 10px;

  .order-detail-container {
    ::v-deep .title {
      font-size: 25px;
      font-weight: 400;
      text-align: center;
      margin: 1rem 0 2rem;
    }

    ::v-deep .row {
      margin: 20px 0px;
    }

    ::v-deep .image {
      padding-right: 0px;
      padding-left: 0px;
      width: 80px;
      height: 80px;
    }
  }
}

.tabs-wrapper {
  padding: 15px;
  background-color: white;
  border-radius: 10px;
}

.cs-bg {
  background-color: rgba(32, 65, 155, 0.12);
  border-radius: 10px;
  width: fit-content;
}

.not-payment ::v-deep {
  background-color: rgb(243, 129, 8) !important;
  color: white !important;
}

.reject ::v-deep {
  color: white !important;
  background-color: red !important;
}

.payment ::v-deep {
  color: white !important;
  background-color: #17ad73 !important;
}

.status {
  padding: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 4px;
  font-size: 16px;
  user-select: none;
  width: 100%;
}

.select-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.menu-select {
  background-color: #fff;
  position: absolute;
  bottom: 0px;
  right: 0;
  z-index: 2;
  width: 100%;
  border-radius: 6px;
  box-shadow: 8px 8px 25px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.cs-saling {
  max-width: 1000px;
}

.cs-menu-item {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    background-color: rgba(21, 76, 205, 0.1);
  }

  span {
    display: block;
    // width: 100%;
    // height: 100%;
  }
}

.cs-item-active {
  background-color: #20419b !important;
  color: white;
}

::v-deep .cs-btable * {
  color: #000;
  font-family: "Times New Roman", Times, serif;

  thead * {
    font-size: 20px;
    text-transform: none !important;
  }

  tbody {
    font-size: 20px;
  }
}

.cs-text {
  text-decoration: underline;
}

.circle {
  background: #d5d4db;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.note {
  border: 1px solid #b5c7f7;
  border-radius: 4px;
  padding: 4px 8px;
  background: #f4f7ff;
  color: #000;
  font-size: 13px;
}

.text-base {
  font-size: 16px;
  line-height: 24px;
}
</style>
